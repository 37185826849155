import {
  EbpLandgCase,
  ReferralLandg,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { Button, FormLabel } from '@percihealth/react';
import LatestClinicalReportTable from '../LatestClinicalReportTable';
import { ReferralDownloadFileLink } from './ReferralDownloadFileLink';
import ReferralDocumentsTable from './ReferralDocumentsTable';

export const LandgReferralDetails = ({
  ebpCase,
  referral,
  readonly,
}: {
  ebpCase: EbpLandgCase;
  referral: ReferralLandg;
  readonly: boolean;
}) => {
  return (
    <>
      <div>
        <FormLabel bold>Case manager's email</FormLabel>
        <span>{referral.caseManager?.email ?? ''}</span>
      </div>
      <div>
        <FormLabel bold>GIP code</FormLabel>
        <span>{referral.gip?.code}</span>
      </div>
      <div>
        <FormLabel bold>Medical history</FormLabel>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            alignItems: 'start',
          }}
        >
          <div>
            Sent to EHR:{' '}
            {ebpCase.patient.medicalHistory?.sentToHealee?.at ? (
              <div>
                {dateTimeToLocalDateTimeString(
                  ebpCase.patient.medicalHistory.sentToHealee.at,
                )}{' '}
                <br />
                by {ebpCase.patient.medicalHistory.sentToHealee.by}
              </div>
            ) : (
              'No'
            )}
          </div>
          <Button
            disabled={
              readonly || !!ebpCase.patient.medicalHistory?.sentToHealee
            }
            onClick={() => {
              window.open(
                `/cases/${ebpCase.id}/medical-history-validation`,
                '_blank',
              );
            }}
          >
            Verify
          </Button>
        </div>
      </div>
      <div>
        <FormLabel bold>Latest clinical report</FormLabel>
        <LatestClinicalReportTable
          referralId={referral.id}
          latestClinicalReport={referral.documents?.latestClinicalReport}
          readonly={readonly}
        />
      </div>
      <div>
        <FormLabel bold>Consent form</FormLabel>
        <ReferralDownloadFileLink
          referralId={referral.id}
          fileName={referral.documents?.consent?.name}
          filePath={referral.documents?.consent?.path}
        />
      </div>
      <div>
        <FormLabel bold>Referral forms</FormLabel>
        <ReferralDocumentsTable
          referralId={referral.id}
          forms={referral.documents?.referrals ?? []}
          readonly={readonly}
        />
      </div>
    </>
  );
};
