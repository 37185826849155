import { EbpCase } from '@packages/core-shared';
import { useMemo } from 'react';
import { useGetTypeformFatigueChanges } from './fields/useGetTypeformFatigueChanges';
import { useGetTypeformRtwSe } from './fields/useGetTypeformRtwSe';
import { useGetMyCawConcerns } from './fields/useGetMyCawConcerns';
import { useGetTypeformOverallWellbeingChange } from './fields/useGetTypeformOverallWellbeingChange';
import { useGetOtherFactors } from './fields/useGetOtherFactors';
import { useGetTypeformImpactsChanges } from './fields/useGetTypeformImpactsChanges';
import { useGetMostImportantAspectOfSupport } from './fields/useGetMostImportantAspectOfSupport';

export const useGetTypeformChanges = ({
  ebpCase,
}: {
  ebpCase: EbpCase | null;
}) => {
  const { mycawConcern1, mycawConcern2 } = useGetMyCawConcerns(ebpCase);
  const overallWellbeing = useGetTypeformOverallWellbeingChange(ebpCase);
  const otherFactors = useGetOtherFactors(ebpCase);
  const impacts = useGetTypeformImpactsChanges(ebpCase);
  const fatigue = useGetTypeformFatigueChanges(ebpCase);
  const returnToWorkSelfEfficacy = useGetTypeformRtwSe(ebpCase);
  const mostImportantAspectOfSupport =
    useGetMostImportantAspectOfSupport(ebpCase);

  const result = useMemo(
    () => ({
      mycawConcern1,
      mycawConcern2,
      overallWellbeing,
      impacts,
      otherFactors,
      mostImportantAspectOfSupport,
      fatigue,
      returnToWorkSelfEfficacy,
    }),
    [
      mycawConcern1,
      mycawConcern2,
      overallWellbeing,
      impacts,
      otherFactors,
      mostImportantAspectOfSupport,
      fatigue,
      returnToWorkSelfEfficacy,
    ],
  );

  return result;
};
