import { CareDocumentType, WorkFocusedPlanData } from '@packages/core-shared';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useMemo } from 'react';
import { defaultWorkImpacts } from './defaultWorkImpacts';

export const useLoadWorkFocusedPlan = (workFocusPlanId?: string) => {
  const docLoader = useGetFirestoreDocument<WorkFocusedPlanData>(
    'documents',
    workFocusPlanId ?? null,
  );

  const record = useMemo(() => {
    const doc = docLoader.record;
    migrateOldDataStructure(doc);

    return doc;
  }, [docLoader.record]);

  const result = useMemo(
    () => ({
      record,
      loading: docLoader.loading,
      errorMessage: docLoader.errorMessage,
    }),
    [record, docLoader.loading, docLoader.errorMessage],
  );

  return result;
};

export const useLoadWorkFocusedPlanAutosaved = (caseId: string) => {
  const docAutosaved = useGetFirestoreDocument<WorkFocusedPlanData>(
    `cases/${caseId}/documents-autosaved`,
    CareDocumentType.WorkFocusedPlan,
  );

  const record = useMemo(() => {
    const doc = docAutosaved.record;
    migrateOldDataStructure(doc);

    return doc;
  }, [docAutosaved.record]);

  const result = useMemo(
    () => ({
      record,
      loading: docAutosaved.loading,
      errorMessage: docAutosaved.errorMessage,
    }),
    [record, docAutosaved.loading, docAutosaved.errorMessage],
  );

  return result;
};

const migrateOldDataStructure = (doc: WorkFocusedPlanData | null) => {
  if (!doc) {
    return;
  }
  // Migration for old data structure
  doc.workImpact = doc.workImpact ?? defaultWorkImpacts;
  for (const wi of doc.workImpact) {
    if ((wi as any)?.title && !wi?.header) {
      wi.header = (wi as any).title;
    }
  }
  doc.planToOvercomeChallenges = doc.planToOvercomeChallenges ?? [];

  if (
    doc.patient &&
    doc.patient.work &&
    doc.patient.work.hideHopingToReturnToWorkIn === undefined
  ) {
    doc.patient.work.hideHopingToReturnToWorkIn = false;
  }

  return doc;
};
