import { useCallback, useMemo, useState } from 'react';
import { useFirebase } from '../../context';
import { CasesApiRepository } from './CasesApiRepository';

export const useUpdateImprovedKeyChallenge = () => {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { auth } = useFirebase();
  const casesApiRepository = useMemo(
    () => new CasesApiRepository(auth),
    [auth],
  );

  const update = useCallback(
    async (
      caseId: string,
      improvedKeyChallenge: boolean | null,
    ): Promise<boolean> => {
      try {
        setUpdating(true);
        setErrorMessage(null);
        await casesApiRepository.updateImprovedKeyChallenge(
          caseId,
          improvedKeyChallenge,
        );

        return true;
      } catch (err) {
        console.error(err);
        setErrorMessage(
          err instanceof Error ? err.message : JSON.stringify(err),
        );
        return false;
      } finally {
        setUpdating(false);
      }
    },
    [],
  );

  return { update, updating, errorMessage };
};
