import {
  CareDocumentOutcome,
  EbpCase,
  MycawFollowUpTypeform,
  MycawFollowUpTypeformHelper,
  MycawInitialTypeform,
  MycawInitialTypeformHelper,
} from '@packages/core-shared';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useEffect, useMemo, useState } from 'react';
import { changeToIntString } from './changeToIntString';

type MycawOutcome = CareDocumentOutcome & { concern?: string };

export const useGetMyCawConcerns = (ebpCase: EbpCase | null) => {
  const [mycawConcern1, setMycawConcern1] = useState<MycawOutcome>({});
  const [mycawConcern2, setMycawConcern2] = useState<MycawOutcome>({});

  const { record: mycawInitialForm } =
    useGetFirestoreDocument<MycawInitialTypeform>(
      'forms',
      ebpCase?.forms?.mycawInitialFormId,
    );

  const { record: mycawFollowUpForm } =
    useGetFirestoreDocument<MycawFollowUpTypeform>(
      'forms',
      ebpCase?.forms?.mycawFollowUpFormId,
    );

  useEffect(() => {
    const initialTypeformHelper = new MycawInitialTypeformHelper(
      mycawInitialForm,
    );
    const followupTypeformHelper = new MycawFollowUpTypeformHelper(
      mycawFollowUpForm,
    );

    const initialConcern1 = initialTypeformHelper.getInitialConcern1();
    const followUpConcern1Level = followupTypeformHelper.getConcern1Level();

    const concern1Change =
      initialConcern1?.level !== null && followUpConcern1Level !== null
        ? parseInt(followUpConcern1Level) - parseInt(initialConcern1?.level)
        : undefined;

    setMycawConcern1({
      concern: initialConcern1.concern ?? undefined,
      initial: initialConcern1?.level ?? undefined,
      followUp: followUpConcern1Level ?? undefined,
      change: changeToIntString(concern1Change),
    });

    const initialConcern2 = initialTypeformHelper.getInitialConcern2();
    const followUpConcern2Level = followupTypeformHelper.getConcern2Level();

    const concern2Change =
      initialConcern2?.level !== null && followUpConcern2Level !== null
        ? parseInt(followUpConcern2Level) - parseInt(initialConcern2?.level)
        : undefined;

    setMycawConcern2({
      concern: initialConcern2.concern ?? undefined,
      initial: initialConcern2?.level ?? undefined,
      followUp: followUpConcern2Level ?? undefined,
      change: changeToIntString(concern2Change),
    });
  }, [mycawInitialForm, mycawFollowUpForm]);

  const result = useMemo(
    () => ({
      mycawConcern1,
      mycawConcern2,
    }),
    [mycawConcern1, mycawConcern2],
  );

  return result;
};
