import { CareDocumentColumn } from '@packages/core-shared';

export const defaultWorkImpacts: CareDocumentColumn[] = [
  {
    header: 'Cancer and treatment',
    values: [
      'Pre existing conditions',
      'Prognosis and symptoms',
      'Treatment specifics',
      'Physical, psychological, social',
    ],
  },
  {
    header: 'Person related',
    values: [
      'Perceived impact of cancer on work',
      'Meaning of work',
      'Attitudes towards work',
      'Change in priorities',
      'Expectation of recovery and work ability',
      'Coping and wellbeing at work',
      'Socio demographic',
    ],
  },
  {
    header: 'Healthcare services and support networks',
    values: [
      'Healthcare support systems',
      'Hospital appointments',
      'CNS',
      'Local support',
      'Charity',
      'Allied healthcare professionals',
      'Insurance financial support',
      'Legal support',
      'Community support',
    ],
  },
  {
    header: 'Job demands and workplace support',
    values: [
      'Psychological demands',
      'Physical demands',
      'Available adjustments',
      'Supportive line manager',
      'Job security',
    ],
  },
];
