import {
  EbpCaseReturnedToWork,
  NegativeReturnedToWorkRationale,
  negativeReturnedToWorkRationaleToString,
  PositiveReturnedToWorkRationale,
  positiveReturnedToWorkRationaleToString,
  ReturnedToWorkRationale,
} from '@packages/core-shared';

import { useEffect, useState } from 'react';
import { useUpdateReturnedToWork } from '../../../../../../api';
import { isEqual } from 'lodash';

import styles from './CaseReturnToWork.module.scss';
import {
  AlertError,
  Button,
  DropdownSearch,
  TextArea,
} from '@percihealth/react';

const returnToWorkOptions = [
  {
    value: '-',
    name: '-',
  },
  {
    value: 'true',
    name: 'Yes',
  },
  {
    value: 'false',
    name: 'No',
  },
];

const returnToWorkOptionToState = (value: string) => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

const returnToWorkStateToOption = (value: boolean | null) => {
  switch (value) {
    case true:
      return 'true';
    case false:
      return 'false';
    default:
      return '-';
  }
};

const positiveRatinaleOptions = Object.values(
  PositiveReturnedToWorkRationale,
).map((value) => ({
  value: value,
  name: positiveReturnedToWorkRationaleToString(value),
}));

const negativeRatinaleOptions = Object.values(
  NegativeReturnedToWorkRationale,
).map((value) => ({
  value: value,
  name: negativeReturnedToWorkRationaleToString(value),
}));

export default function CaseReturnToWork({
  caseId,
  returnedToWork,
}: {
  caseId: string;
  returnedToWork?: EbpCaseReturnedToWork | null;
}) {
  const returnedToWorkUpdater = useUpdateReturnedToWork();

  const [returnedToWorkValue, setReturnedToWorkValue] = useState<
    boolean | null
  >(
    returnedToWork !== undefined && returnedToWork !== null
      ? returnedToWork.returned
      : null,
  );
  const [positiveRationale, setPositiveRationale] =
    useState<PositiveReturnedToWorkRationale>(positiveRatinaleOptions[0].value);
  const [negativeRationale, setNegativeRationale] =
    useState<NegativeReturnedToWorkRationale>(negativeRatinaleOptions[0].value);

  const [notes, setNotes] = useState('');

  useEffect(() => {
    setReturnedToWorkValue(
      returnedToWork !== undefined && returnedToWork !== null
        ? returnedToWork.returned
        : null,
    );

    if (returnedToWork) {
      if (returnedToWork.returned) {
        setPositiveRationale(
          returnedToWork.rationale as PositiveReturnedToWorkRationale,
        );
        setNegativeRationale(negativeRatinaleOptions[0].value);
      } else {
        setPositiveRationale(positiveRatinaleOptions[0].value);
        setNegativeRationale(
          returnedToWork.rationale as NegativeReturnedToWorkRationale,
        );
      }
    } else {
      setPositiveRationale(positiveRatinaleOptions[0].value);
      setNegativeRationale(negativeRatinaleOptions[0].value);
    }

    setNotes(returnedToWork?.notes ?? '');
  }, [returnedToWork]);

  const hasReturnedToWork = returnedToWorkValue !== null;

  const rtwToSave =
    returnedToWorkValue != null
      ? ({
          returned: returnedToWorkValue,
          rationale: (returnedToWorkValue
            ? positiveRationale
            : negativeRationale) as ReturnedToWorkRationale,
          notes,
        } satisfies EbpCaseReturnedToWork)
      : null;

  const hasChanges = !isEqual(returnedToWork ?? null, rtwToSave);

  return (
    <div>
      <div className={styles['rtw']}>
        <div className={styles['rtw-grid']}>
          <div className={styles['rtw-grid-title']}>
            Has this member returned to work?
          </div>
          {hasReturnedToWork ? (
            <div className={styles['rtw-grid-title']}>Rationale:</div>
          ) : (
            <div />
          )}
          {hasReturnedToWork ? (
            <div className={styles['rtw-grid-title']}>Notes:</div>
          ) : (
            <div />
          )}
          <div className={styles['rtw-dropdown']}>
            <DropdownSearch
              multiple={false}
              value={returnToWorkStateToOption(returnedToWorkValue)}
              options={returnToWorkOptions}
              disabled={returnedToWorkUpdater.updating}
              onChange={(v: any) => {
                setReturnedToWorkValue(returnToWorkOptionToState(v));
              }}
            />
          </div>

          {hasReturnedToWork ? (
            <div>
              {returnedToWorkValue === true ? (
                <DropdownSearch
                  multiple={false}
                  value={positiveRationale}
                  options={positiveRatinaleOptions}
                  disabled={returnedToWorkUpdater.updating}
                  onChange={(v: any) => {
                    setPositiveRationale(v);
                  }}
                />
              ) : (
                <DropdownSearch
                  multiple={false}
                  value={negativeRationale}
                  options={negativeRatinaleOptions}
                  disabled={returnedToWorkUpdater.updating}
                  onChange={(v: any) => {
                    setNegativeRationale(v);
                  }}
                />
              )}
            </div>
          ) : (
            <div />
          )}
          {hasReturnedToWork ? (
            <TextArea
              className={styles['rtw-notes']}
              disabled={returnedToWorkUpdater.updating}
              rows={5}
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          ) : (
            <div />
          )}
        </div>
        {hasReturnedToWork && (
          <div className={styles['rtw-actions']}>
            <Button
              disabled={!hasChanges || returnedToWorkUpdater.updating}
              onClick={() => {
                window.location.reload();
              }}
            >
              Cancel
            </Button>
            <Button
              level="secondary"
              disabled={!hasChanges}
              submitting={returnedToWorkUpdater.updating}
              onClick={() => returnedToWorkUpdater.update(caseId, rtwToSave)}
            >
              Save
            </Button>
          </div>
        )}
        {returnedToWorkUpdater.errorMessage && (
          <AlertError>{returnedToWorkUpdater.errorMessage}</AlertError>
        )}
      </div>
    </div>
  );
}
