import {
  CareDocumentType,
  EndOfCareSummary,
  EndOfCareSummaryData,
} from '@packages/core-shared';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useMemo } from 'react';

export const useLoadEndOfCareSummary = (docId?: string) => {
  const docLoader = useGetFirestoreDocument<EndOfCareSummary>(
    'documents',
    docId ?? null,
  );

  const result = useMemo(() => {
    const doc = docLoader.record;
    migrateOldDataStructure(doc);

    return doc;
  }, [docLoader.record]);

  return {
    record: result,
    loading: docLoader.loading,
    errorMessage: docLoader.errorMessage,
  };
};

export const useLoadEndOfCareSummaryAutosaved = (caseId: string) => {
  const docAutosaved = useGetFirestoreDocument<EndOfCareSummaryData>(
    `cases/${caseId}/documents-autosaved`,
    CareDocumentType.EndOfCareSummary,
  );

  const result = useMemo(() => {
    const doc = docAutosaved.record;
    migrateOldDataStructure(doc);

    return doc;
  }, [docAutosaved.record]);

  return {
    record: result,
    loading: docAutosaved.loading,
    errorMessage: docAutosaved.errorMessage,
  };
};

const migrateOldDataStructure = (doc: EndOfCareSummaryData | null) => {
  // Migration for old data structure
  if (!doc) {
    return;
  }

  if (!doc.expertSummary || typeof doc.expertSummary === 'string') {
    doc.expertSummary = {
      motivation: '',
      progressAgainstGoals: '',
      assessmentOfCapabilitiesReturnToWork: '',
    };
  }

  return doc;
};
