import { AlertError, Button, Spinner } from '@percihealth/react';
import { getDownloadURL, ref } from 'firebase/storage';
import { useMemo, useState } from 'react';
import {
  dateTimeToLocalDateTimeString,
  displayEbpDocumentType,
  EbpCase,
  CareDocument,
  CareDocumentType,
  LandgCareDocument,
  ReferralLandg,
  OrganizationId,
  Referral,
} from '@packages/core-shared';
import { PcpToHealixModal } from './PcpToHealixModal';
import { DocumentToHealeeModal } from './DocumentToHealeeModal';
import { useNavigate } from 'react-router-dom';

import styles from './DocumentsTable.module.scss';
import { DocumentToLandgModal } from './DocumentToLandgModal';
import { useGetFirestoreDocument } from '../../../../../hooks/useGetFirestoreDocument';
import { useFirebase } from '../../../../../context';

export const EbpDocumentsTable = ({
  caseId,
  ebpCase,
  organizationId,
  allowEditPdfs,
  allowUploadPdfs,
  documentsLoader,
}: {
  caseId: string;
  ebpCase: EbpCase;
  organizationId: string;
  allowEditPdfs: boolean;
  allowUploadPdfs: boolean;
  documentsLoader: {
    loading: boolean;
    errorMessage: string | null;
    records: CareDocument[];
  };
}) => {
  const navigate = useNavigate();

  const { storage } = useFirebase();

  const records = useMemo(() => {
    return (
      documentsLoader.records?.map((doc) => ({
        ...doc,
        displayType: displayEbpDocumentType(doc.type),
      })) ?? []
    );
  }, [documentsLoader.records]);

  const [pcpIdToHealix, setPcpIdToHealix] = useState<string | null>(null);
  const [documentIdToLang, setDocumentIdToLandg] = useState<string | null>(
    null,
  );

  const [documentIdToHealee, setDocumentIdToHealee] = useState<string | null>(
    null,
  );

  const isLandg = useMemo(
    () => organizationId === OrganizationId.landg,
    [organizationId],
  );

  const { record: referral, loading: isReferralLoading } =
    useGetFirestoreDocument<Referral>('referrals', ebpCase?.referralId);

  if (documentsLoader.loading) return <Spinner />;
  else if (documentsLoader.errorMessage)
    return <AlertError>{documentsLoader.errorMessage}</AlertError>;

  return (
    <div className="full-width">
      <div className={styles.buttons}>
        <Button
          disabled={!allowEditPdfs}
          onClick={() => navigate(`/cases/${caseId}/care-documents/pcp`)}
        >
          Create {displayEbpDocumentType(CareDocumentType.PersonalisedCarePlan)}
        </Button>

        {isLandg && (
          <>
            <Button
              disabled={!allowEditPdfs}
              onClick={() =>
                navigate(`/cases/${caseId}/care-documents/work-focused-plan`)
              }
            >
              Create {displayEbpDocumentType(CareDocumentType.WorkFocusedPlan)}
            </Button>

            <Button
              disabled={!allowEditPdfs}
              onClick={() =>
                navigate(
                  `/cases/${caseId}/care-documents/initial-assessment-summary`,
                )
              }
            >
              Create{' '}
              {displayEbpDocumentType(
                CareDocumentType.InitialAssessmentSummary,
              )}
            </Button>

            <Button
              disabled={!allowEditPdfs}
              onClick={() =>
                navigate(`/cases/${caseId}/care-documents/interim-care-summary`)
              }
            >
              Create{' '}
              {displayEbpDocumentType(CareDocumentType.InterimCareSummary)}
            </Button>

            <Button
              disabled={!allowEditPdfs}
              onClick={() =>
                navigate(`/cases/${caseId}/care-documents/end-of-care-summary`)
              }
            >
              Create {displayEbpDocumentType(CareDocumentType.EndOfCareSummary)}
            </Button>
          </>
        )}
      </div>
      <table className={styles.table}>
        <thead>
          <tr style={{ textAlign: 'left' }}>
            <th>Type</th>
            <th>Created</th>
            <th>Preview</th>
            <th>Upload</th>
            {isLandg && <th>Share</th>}
          </tr>
        </thead>
        <tbody>
          {records.map((doc) => (
            <tr key={doc.id}>
              <td>
                <span>{doc.displayType}</span>
              </td>
              <td>
                <span>{dateTimeToLocalDateTimeString(doc.createdAt)}</span>
              </td>
              <td>
                {doc.storageRef && (
                  <a
                    onClick={async (e) => {
                      e.preventDefault();
                      // If a very old document object
                      const previewUrl = await getDownloadURL(
                        ref(storage, doc.storageRef!),
                      );

                      // Open the file in a new tab, so it is not automatically downloaded to the local storage
                      window.open(previewUrl, '_blank');
                    }}
                  >
                    View
                  </a>
                )}
              </td>
              <td>
                {!doc.uploadedToHealee && (
                  <Button
                    disabled={!allowUploadPdfs || doc.id === documentIdToHealee}
                    onClick={() => setDocumentIdToHealee(doc.id)}
                  >
                    Upload to Healee
                  </Button>
                )}
                {doc.uploadedToHealee && (
                  <div>
                    Uploaded at
                    <br />
                    {dateTimeToLocalDateTimeString(doc.uploadedToHealee.at)}
                  </div>
                )}
              </td>
              {isLandg && (
                <td>
                  {!(doc as LandgCareDocument).sentToCaseManager && (
                    <Button
                      disabled={
                        !allowEditPdfs ||
                        doc.id === documentIdToLang ||
                        !(referral as ReferralLandg)?.caseManager?.email
                      }
                      onClick={() => setDocumentIdToLandg(doc.id)}
                    >
                      {isReferralLoading && <Spinner />}
                      {!isReferralLoading && 'Send to L&G case manager'}
                    </Button>
                  )}
                  {(doc as LandgCareDocument).sentToCaseManager && (
                    <div>
                      Sent at
                      <br />
                      {dateTimeToLocalDateTimeString(
                        (doc as LandgCareDocument).sentToCaseManager!.at,
                      )}
                    </div>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <DocumentToHealeeModal
        caseId={caseId}
        documentId={documentIdToHealee}
        onClose={() => {
          setDocumentIdToHealee(null);
        }}
      />

      <PcpToHealixModal
        caseId={caseId}
        pcpId={pcpIdToHealix}
        onClose={() => setPcpIdToHealix(null)}
      />

      <DocumentToLandgModal
        caseId={caseId}
        documentId={documentIdToLang}
        onClose={() => setDocumentIdToLandg(null)}
      />
    </div>
  );
};
