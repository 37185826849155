import { EbpCase, EbpCaseStatus } from '@packages/core-shared';
import { collection, orderBy, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useLoadFirestoreList } from '../../hooks/useLoadFirestoreList';
import { useFirebase } from '../../context';

export const useLoadCases = (
  fromDate: Date | null,
  toDate: Date | null,
  expertId: string | null,
  organizationId: string | null,
  showOnlyActiveCases: boolean,
  referredType: string | null,
  // followedUpFilter: boolean[],
) => {
  const { firestore } = useFirebase();

  const queryResult = useMemo(() => {
    let q = query(collection(firestore, 'cases'));
    if (fromDate) {
      q = query(q, where('createdAt', '>=', fromDate));
    }

    if (toDate) {
      q = query(q, where('createdAt', '<=', toDate));
    }

    if (expertId) {
      q = query(q, where('expertId', '==', expertId));
    }

    if (organizationId) {
      q = query(q, where('organization.id', '==', organizationId));
    }

    // if (followedUpFilter.length === 1) {
    //   q = query(q, where('followedUp', '==', followedUpFilter[0]));
    // }

    if (showOnlyActiveCases) {
      q = query(
        q,
        where('status', '!=', EbpCaseStatus.discharged),
        where('hasAppointment', '==', true),
      );
    }

    if (referredType) {
      q = query(
        q,
        where('hasReferralBeforeCase', '==', referredType === 'referred'),
      );
    }

    q = query(q, orderBy('createdAt', 'desc'));
    return q;
  }, [
    firestore,
    fromDate,
    toDate,
    expertId,
    organizationId /*, followedUpFilter */,
    showOnlyActiveCases,
    referredType,
  ]);

  const loadFirestoreList = useLoadFirestoreList<EbpCase>(queryResult);
  return loadFirestoreList;
};
