import {
  CareDocumentOutcome,
  EbpCase,
  MycawFollowUpTypeform,
  MycawFollowUpTypeformHelper,
} from '@packages/core-shared';
import { useEffect, useState } from 'react';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';

export const useGetOtherFactors = (ebpCase: EbpCase | null) => {
  const [otherFactors, setOtherFactors] = useState<
    CareDocumentOutcome | undefined
  >(undefined);

  const { record: mycawFollowUpForm } =
    useGetFirestoreDocument<MycawFollowUpTypeform>(
      'forms',
      ebpCase?.forms?.mycawFollowUpFormId,
    );

  useEffect(() => {
    const followupTypeformHelper = new MycawFollowUpTypeformHelper(
      mycawFollowUpForm,
    );

    const otherFactorsValue = followupTypeformHelper.getOtherFactors();
    setOtherFactors(
      otherFactorsValue
        ? {
            initial: 'N/A baseline',
            followUp: followupTypeformHelper.getOtherFactors() ?? undefined,
            change: 'N/a',
          }
        : undefined,
    );
  }, [mycawFollowUpForm]);

  return otherFactors;
};
