import { useState } from 'react';
import { AlertError, DropdownSearch, Spinner } from '@percihealth/react';
import { useUpdateImprovedKeyChallenge } from '../../../../../../api';
import styles from './CaseImprovedConcern.module.scss';

const improvedOptions = [
  {
    value: '-',
    name: 'Somewhat',
  },
  {
    value: 'true',
    name: 'Yes',
  },
  {
    value: 'false',
    name: 'No',
  },
];

const improvedOptionToState = (value: string) => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

const improvedStateToOption = (value: boolean | null) => {
  switch (value) {
    case true:
      return 'true';
    case false:
      return 'false';
    default:
      return '-';
  }
};

export default function CaseImprovedConcern({
  caseId,
  improvedKeyChallenge,
}: {
  caseId: string;
  improvedKeyChallenge?: boolean | null;
}) {
  const improvedKeyChallengeUpdater = useUpdateImprovedKeyChallenge();

  const [improvedKeyChallengeValue, setImprovedKeyChallengeValue] = useState<
    boolean | null
  >(improvedKeyChallenge !== undefined ? improvedKeyChallenge : null);

  return (
    <div>
      {improvedKeyChallengeUpdater.errorMessage && (
        <AlertError>{improvedKeyChallengeUpdater.errorMessage}</AlertError>
      )}

      <div>
        Has Perci's intervention improved this member's key challenge or
        concern?
      </div>
      <div className={styles.dropdown}>
        <DropdownSearch
          multiple={false}
          value={improvedStateToOption(improvedKeyChallengeValue)}
          options={improvedOptions}
          closeOnSelect
          disabled={improvedKeyChallengeUpdater.updating}
          onChange={(v: any) => {
            const newValue = improvedOptionToState(v);

            // push React stack update with setTimeout,
            // otherwise, dropdown won't close
            setTimeout(async () => {
              const updated = await improvedKeyChallengeUpdater.update(
                caseId,
                newValue,
              );
              if (updated) {
                setImprovedKeyChallengeValue(newValue);
              }
            });
          }}
        />
        <div>{improvedKeyChallengeUpdater.updating && <Spinner />}</div>
      </div>
    </div>
  );
}
