import { Button, classList } from '@percihealth/react';
import { dateTimeToLondonDateTimeString, EbpCase } from '@packages/core-shared';
import { useEffect, useState } from 'react';
import { UndischargeConfirmationModal } from './UndischargeConfirmationModal';
import styles from './DischargedCaseStatusBar.module.scss';

export default function DischargedCaseStatusBar({
  ebpCase,
}: {
  ebpCase: EbpCase;
}) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(false);
  }, [ebpCase.id]);

  return (
    <div className={classList(styles.container, styles.discharged)}>
      <div>
        <b>DISCHARGED</b>
        <div> Start: {dateTimeToLondonDateTimeString(ebpCase.createdAt)}</div>
        <div> End: {dateTimeToLondonDateTimeString(ebpCase.dischargedAt)}</div>
      </div>
      <div>
        <Button
          className={styles.submit}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Undo discharge
        </Button>
      </div>
      <UndischargeConfirmationModal
        open={showModal}
        caseId={ebpCase.id}
        patientFullname={`${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}
