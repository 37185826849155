import { AlertError, Button, Checkbox, FormLabel } from '@percihealth/react';
import SafeguardCheckHistory from '../../../../../SafeguardCheckHistory';
import {
  RtwSeTypeform,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { useMemo, useState } from 'react';
import { FormsApiRepository } from '../../../../../../../api';
import { getOrderedTypeformFields } from '../../getOrderedTypeformFields';
import { getCoercedTypeformFieldTitle } from '../../getCoercedTypeformFieldTitle';
import { useFirebase } from '../../../../../../../context';
import styles from './RtwSeTypeformComponent.module.scss';

interface Props {
  form: RtwSeTypeform;
  allowEditSafeguardCheck: boolean;
}

export default function RtwSeTypeformComponent({
  form,
  allowEditSafeguardCheck,
}: Props) {
  const { auth } = useFirebase();

  const [updatingSafeguard, setUpdatingSafeguard] = useState(false);
  const [updateSafeguardErrorMessage, setUpdateSafeguardErrorMessage] =
    useState<string | null>(null);

  const formsApiRepository = useMemo(
    () => new FormsApiRepository(auth),
    [auth],
  );

  const updateSafeguard = async (
    form: RtwSeTypeform,
    safeguardCheck: boolean,
  ) => {
    try {
      setUpdatingSafeguard(true);
      setUpdateSafeguardErrorMessage(null);
      await formsApiRepository.updateSafeguard(form.id, safeguardCheck);
      // update the UI
      form.safeguardCheck = safeguardCheck;
    } catch (err) {
      console.error(err);
      setUpdateSafeguardErrorMessage(
        err instanceof Error ? err.message : JSON.stringify(err),
      );
    } finally {
      setUpdatingSafeguard(false);
    }
  };

  const orderedFields = getOrderedTypeformFields(form);

  return (
    <div className={styles.container}>
      {updateSafeguardErrorMessage && (
        <AlertError>{updateSafeguardErrorMessage}</AlertError>
      )}
      <div>
        <FormLabel bold>Safeguarding check</FormLabel>
        <div className={styles.safeguard}>
          <Checkbox
            text="Checked"
            className={styles.checkmark}
            disabled
            value=""
            checked={form.safeguardCheck}
          />
          <Button
            onClick={() => {
              updateSafeguard(form, !form.safeguardCheck);
            }}
            submitting={updatingSafeguard}
            disabled={!allowEditSafeguardCheck}
          >
            {form.safeguardCheck ? 'Mark as unchecked' : 'Mark as checked'}
          </Button>
        </div>
        <SafeguardCheckHistory formId={form.id} />
      </div>
      <hr />
      <div>
        <FormLabel bold>Time to complete</FormLabel>
        <span>{form.time_to_complete}</span>
      </div>
      <div>
        <FormLabel bold>Created</FormLabel>
        <span>{dateTimeToLocalDateTimeString(form.createdAt)}</span>
      </div>
      <div>
        <FormLabel bold>Scores</FormLabel>
        <p>
          RTW-SE: <b>{form.var_rtw_se}</b>
        </p>
      </div>
      <div className="full-width">
        <div>
          <FormLabel bold>Typeform responses</FormLabel>
          <ol>
            {orderedFields
              .filter((field) => field.ref.includes('rtw-se'))
              .map((field) => (
                <li key={field.ref} className={styles.qa}>
                  {getCoercedTypeformFieldTitle(field.title, form)}
                  <p className={styles.answer}>{field.value}</p>
                </li>
              ))}
          </ol>
        </div>
      </div>
    </div>
  );
}
