import {
  CareDocumentOutcome,
  EbpCase,
  MycawFollowUpTypeform,
  MycawFollowUpTypeformHelper,
  MycawInitialTypeform,
  MycawInitialTypeformHelper,
} from '@packages/core-shared';
import { changeToIntString } from './changeToIntString';
import { useEffect, useState } from 'react';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';

export const useGetTypeformOverallWellbeingChange = (
  ebpCase: EbpCase | null,
) => {
  const [overallWellbeing, setOverallWellbeing] = useState<CareDocumentOutcome>(
    {},
  );

  const { record: mycawInitialForm } =
    useGetFirestoreDocument<MycawInitialTypeform>(
      'forms',
      ebpCase?.forms?.mycawInitialFormId,
    );

  const { record: mycawFollowUpForm } =
    useGetFirestoreDocument<MycawFollowUpTypeform>(
      'forms',
      ebpCase?.forms?.mycawFollowUpFormId,
    );

  useEffect(() => {
    const initialTypeformHelper = new MycawInitialTypeformHelper(
      mycawInitialForm,
    );
    const followupTypeformHelper = new MycawFollowUpTypeformHelper(
      mycawFollowUpForm,
    );

    const initialOverallWellbeingLevel =
      initialTypeformHelper.getFeelingLevel() ?? undefined;
    const followUpWellbeingLevel =
      followupTypeformHelper.getFeelingLevel() ?? undefined;

    const wellbeingChange =
      initialOverallWellbeingLevel && followUpWellbeingLevel
        ? parseInt(followUpWellbeingLevel) -
          parseInt(initialOverallWellbeingLevel)
        : undefined;
    setOverallWellbeing({
      initial: initialOverallWellbeingLevel,
      followUp: followUpWellbeingLevel,
      change: changeToIntString(wellbeingChange),
    });
  }, [mycawInitialForm, mycawFollowUpForm]);

  return overallWellbeing;
};
