import { useEffect, useMemo, useState } from 'react';
import styles from './CaseDetailsPage.module.scss';
// import CaseFollowUps from './CaseFollowUps';
import {
  cancerStatusToString,
  dateToLondonLongDateString,
  EbpCase,
  ebpCaseFinalStatuses,
  EbpCaseStatus,
  CareDocumentType,
  EbpLandgCase,
  LandgCareDocument,
  EbpTypeformType,
  OrganizationId,
  ReferralLandg,
  dateTimeToLocalDateTimeString,
  DefaultEbpCaseProgress,
  WorkFocusTypeform,
  RtwSeTypeform,
  ReferralType,
  ReferralGeneric,
  Referral,
} from '@packages/core-shared';
import { useLocation, useParams } from 'react-router-dom';
import {
  AlertError,
  Button,
  Checkbox,
  FormFieldset,
  FormLabel,
  InputText,
  Spinner,
  Tooltip,
} from '@percihealth/react';
import AppointmentsHistory from '../../AppointmentsHistory';
import ExpertChangeHistory from '../../ExpertChangeHistory';
import {
  useLoadServices,
  useUpdateConsentShareDataToInsurer,
  useUpdateExpertId,
} from '../../../../api';
import { EbpDocumentsTable } from './EbpDocumentsTable';
import MycawFollowUpTypeformList from './Typeforms/MycawFollowUp/MycawFollowUpTypeformList';
import { MycawFollowUpTypeformLink } from './Typeforms/MycawFollowUp/MycawFollowUpTypeformLink';
import { MycawInitialTypeformLink } from './Typeforms/MycawInitial/MycawInitialTypeformLink';
import ConsentShareDataToInsurerHistory from '../../ConsentShareDataToInsurerHistory';
import { useSubscribeFirestoreDocument } from '../../../../hooks/useSubscribeFirestoreDocument';
import EmailShareActivity from '../../EmailShareActivity';
import SafeGuardableTypeformList from './Typeforms/SafeGuardable/SafeGuardableTypeformList';
import LevelDropdown from '../../LevelDropdown';
import ExpertInternalNotesTable from './ExpertInternalNotesTable';
import { useSubscribeCareDocumentsByCaseId } from '../../../../api/care-documents/useSubscribeCareDocumentsByCaseId';
import { DischargeConfirmationModal } from './Discharge/DischargeConfirmationModal';
import DischargedCaseStatusBar from './CaseStatusBar/DischargedCaseStatusBar';
import { LandgReferralDetails } from './Referrals/LandgReferralDetails';
import LandgRequestPreauthorizationFormLink from './LandgRequestPreauthorizationFormLink';
import { useGetFirestoreDocument } from '../../../../hooks/useGetFirestoreDocument';
import { MdtFeedbackTypeformLink } from './Typeforms/MdtFeedback/MdtFeedbackTypeformLink';
import MdtFeedbacTypeformList from './Typeforms/MdtFeedback/MdtFeedbackTypeformList';
import CaseProgress from '../../CaseProgress';
import CaseReturnToWork from './CaseResult/CaseReturnToWork';
import CaseImprovedConcern from './CaseResult/CaseImprovedConcern';
import { FatigueTypeformLink } from './Typeforms/Fatigue/FatigueTypeformLink';
import WorkFocusedTypeformComponent from './Typeforms/WorkFocused/WorkFocusedTypeformComponent';
import { RtwSeTypeformLink } from './Typeforms/RtwSe/RtwSeTypeformLink';
import RtwSeTypeformComponent from './Typeforms/RtwSe/RtwSeTypeformComponent';
import { GenericReferralDetails } from './Referrals/GenericReferralDetails';

interface Props {
  allowEditExpert: boolean;
  allowEditPdfs: boolean;
  allowEditTypeforms: boolean;
  allowEditConsentShareDataToInsurer: boolean;
  allowEditPatientLevel: boolean;
}

export default function CaseDetailsPage({
  allowEditExpert,
  allowEditPdfs,
  allowEditTypeforms,
  allowEditConsentShareDataToInsurer,
  allowEditPatientLevel,
}: Props) {
  const params = useParams();
  const { hash } = useLocation();
  const id = params?.id ?? null;

  if (!id) {
    return <div>Case ID (email) should be specified</div>;
  }

  const servicesLoader = useLoadServices();

  const [expertId, setExpertId] = useState('');

  const expertIdUpdater = useUpdateExpertId();
  const consentShareDataToInsurerUpdater = useUpdateConsentShareDataToInsurer();

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useSubscribeFirestoreDocument<EbpCase>('cases', id);

  const readonly = useMemo(
    () => !!ebpCase?.status && ebpCaseFinalStatuses.includes(ebpCase?.status),
    [ebpCase?.status, ebpCaseFinalStatuses],
  );

  useEffect(() => {
    if (!caseLoading && hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [hash, caseLoading]);

  useEffect(() => {
    setExpertId(ebpCase?.expertId ?? '');
  }, [ebpCase]);

  const { record: landgReferral } = useGetFirestoreDocument<ReferralLandg>(
    'referrals',
    ebpCase?.organization.id === OrganizationId.landg
      ? ebpCase?.referralId
      : undefined,
  );

  const documentsLoader = useSubscribeCareDocumentsByCaseId({
    caseId: ebpCase?.id ?? '-1',
  });

  const canDischarge = useMemo(() => {
    return (
      ebpCase?.organization.id === OrganizationId.landg &&
      documentsLoader.records.some(
        (doc) =>
          doc.type === CareDocumentType.EndOfCareSummary &&
          (doc as LandgCareDocument).sentToCaseManager,
      )
    );
  }, [ebpCase?.organization.id, documentsLoader.records]);

  const [showDischargeModal, setShowDischargeModal] = useState(false);

  useEffect(() => {
    setShowDischargeModal(false);
  }, [ebpCase?.id]);

  const referralLoader = useGetFirestoreDocument<Referral>(
    'referrals',
    ebpCase?.referralId,
  );

  if (caseLoading) {
    return <Spinner />;
  }
  if (caseLoadingErrorMessage) {
    return <AlertError>{caseLoadingErrorMessage}</AlertError>;
  }
  if (!ebpCase) {
    return <>Not found</>;
  }

  const showProgress =
    process.env.REACT_APP_FEATURE_SHOW_CASE_PROGRESS === 'true' ||
    process.env.REACT_APP_FEATURE_SHOW_CASE_PROGRESS === '1';

  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}>
          {showProgress && (
            <CaseProgress
              size="medium"
              progress={ebpCase.progress ?? DefaultEbpCaseProgress}
              organizationId={ebpCase.organization?.id}
            />
          )}
          {!showProgress && <div />}
          {/* Discharge */}
          {ebpCase?.status !== EbpCaseStatus.discharged &&
            ebpCase.organization.id === OrganizationId.landg && (
              <div style={{ marginBottom: '-24px' }}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Tooltip
                    disabled={canDischarge}
                    position="left center"
                    trigger={
                      <div>
                        <Button
                          className="discharge-btn"
                          disabled={readonly || !canDischarge}
                          onClick={() => {
                            setShowDischargeModal(true);
                          }}
                        >
                          Discharge
                        </Button>
                      </div>
                    }
                  >
                    <div>
                      available when "End of Care summary" is shared with the
                      L&G case manager
                    </div>
                  </Tooltip>
                </div>
                <DischargeConfirmationModal
                  caseId={ebpCase.id}
                  patientFullname={`${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`}
                  open={showDischargeModal}
                  onClose={() => setShowDischargeModal(false)}
                />
              </div>
            )}
        </div>

        {ebpCase?.status === EbpCaseStatus.discharged && (
          <DischargedCaseStatusBar ebpCase={ebpCase} />
        )}

        <FormFieldset title="General">
          <div>
            <FormLabel bold>Email</FormLabel>
            <span>{ebpCase.id}</span>
          </div>
          <div>
            <FormLabel bold>Organization</FormLabel>
            <span>{ebpCase.organization?.name}</span>
          </div>
          <div>
            <FormLabel bold>Patient name</FormLabel>
            <span>{`${ebpCase.patient?.firstName} ${ebpCase.patient?.lastName}`}</span>
          </div>
          <div>
            <FormLabel bold>Patient DOB</FormLabel>
            <span>
              {ebpCase.patient?.dateOfBirth
                ? dateToLondonLongDateString(ebpCase.patient?.dateOfBirth)
                : '-'}
            </span>
          </div>
          <div>
            <FormLabel bold>Cancer status</FormLabel>
            <span>
              {ebpCase.patient?.cancerStatus
                ? cancerStatusToString(ebpCase.patient?.cancerStatus)
                : '-'}
            </span>
          </div>
          {(readonly || !allowEditExpert) && (
            <div>
              <FormLabel bold>Expert ID</FormLabel>
              <span>{ebpCase.expertId}</span>
            </div>
          )}
          {!readonly && allowEditExpert && (
            <div>
              <FormLabel bold>Expert ID</FormLabel>
              {expertIdUpdater.errorMessage && (
                <AlertError>{expertIdUpdater.errorMessage}</AlertError>
              )}
              <div className={styles.expertId}>
                <InputText
                  disabled={readonly || !allowEditExpert}
                  className={styles.expertIdInput}
                  value={expertId}
                  onChange={(e) => setExpertId(e.currentTarget.value)}
                />
                <Button
                  className={styles.saveExpertIdButton}
                  onClick={() => expertIdUpdater.update(ebpCase.id, expertId)}
                  submitting={expertIdUpdater.updating}
                >
                  Save
                </Button>
              </div>
              <ExpertChangeHistory caseId={ebpCase.id} />
            </div>
          )}
          <div>
            <FormLabel bold>PCP created</FormLabel>
            <span>{ebpCase.documents?.pcp ? 'Yes' : 'No'}</span>
          </div>
          {OrganizationId.landg === ebpCase.organization?.id && (
            <div>
              <FormLabel bold>Level</FormLabel>
              <LevelDropdown
                caseId={ebpCase.id}
                disabled={readonly || !allowEditPatientLevel}
                initialValue={ebpCase.patient.level}
              />
            </div>
          )}
          <div>
            <FormLabel bold>Last activity</FormLabel>
            <span>{dateTimeToLocalDateTimeString(ebpCase.updatedAt)}</span>
          </div>
          <div>
            <FormLabel bold>Created</FormLabel>
            <span>{dateTimeToLocalDateTimeString(ebpCase.createdAt)}</span>
          </div>
          {!readonly && ebpCase.organization.id === OrganizationId.landg && (
            <div>
              <FormLabel bold>Pre-authorisation</FormLabel>
              <LandgRequestPreauthorizationFormLink
                patientFullname={`${ebpCase.patient?.firstName} ${ebpCase.patient?.lastName}`}
                careLevel={ebpCase.patient.level}
                gipCode={landgReferral?.gip?.code}
                // L&G does not have a referrer, using case manager
                // https://perci-health.monday.com/boards/6276536070/pulses/7095615024
                referrerEmail={landgReferral?.caseManager?.email}
              />
            </div>
          )}
        </FormFieldset>

        {/* do not show referral details for Healix */}
        {ebpCase.organization?.id !== OrganizationId.healix &&
          (referralLoader.loading ||
            (referralLoader.errorMessage && (
              <FormFieldset title="Referral details">
                {referralLoader.loading && <Spinner />}
                {referralLoader.errorMessage && (
                  <AlertError>{referralLoader.errorMessage}</AlertError>
                )}
              </FormFieldset>
            )))}

        {referralLoader.record &&
          ebpCase.organization?.id === OrganizationId.landg && (
            <FormFieldset title="Referral details">
              <LandgReferralDetails
                ebpCase={ebpCase as EbpLandgCase}
                referral={referralLoader.record as ReferralLandg}
                readonly={readonly}
              />
            </FormFieldset>
          )}

        {referralLoader.record &&
          referralLoader.record.type === ReferralType.generic && (
            <FormFieldset title="Referral details">
              <GenericReferralDetails
                ebpCase={ebpCase}
                referral={referralLoader.record as ReferralGeneric}
                readonly={readonly}
              />
            </FormFieldset>
          )}

        <FormFieldset title="Consent">
          <div>
            {consentShareDataToInsurerUpdater.errorMessage && (
              <AlertError>
                {consentShareDataToInsurerUpdater.errorMessage}
              </AlertError>
            )}
            <Checkbox
              text="Consent gained to share medical data back to insurer?"
              disabled={
                readonly ||
                !allowEditConsentShareDataToInsurer ||
                consentShareDataToInsurerUpdater.updating
              }
              value=""
              checked={!!ebpCase.consentShareDataToInsurer}
              onChange={(e) => {
                consentShareDataToInsurerUpdater.update(
                  ebpCase.id,
                  e.target.checked,
                );
              }}
            />
            <div>
              {consentShareDataToInsurerUpdater.updating && <Spinner />}
            </div>
          </div>
          <ConsentShareDataToInsurerHistory caseId={ebpCase.id} />
        </FormFieldset>

        <FormFieldset title="Internal notes">
          <ExpertInternalNotesTable caseId={ebpCase.id} readonly={readonly} />
        </FormFieldset>

        <FormFieldset title="Platform activity">
          {servicesLoader.servicesLoading && <Spinner />}
          {servicesLoader.servicesError && (
            <AlertError>{servicesLoader.servicesError}</AlertError>
          )}
          <div id="activity" className="full-width">
            {!servicesLoader.servicesLoading && (
              <AppointmentsHistory
                organizationId={ebpCase?.organization.id}
                services={servicesLoader.services}
                patientEmail={ebpCase.patient?.email}
                disabled={readonly}
              />
            )}
          </div>
          {/* <div className="full-width">
              {servicesLoader.servicesLoading && <Spinner />}
              {servicesLoader.servicesError && (
                <AlertError>{servicesLoader.servicesError}</AlertError>
              )}
              {!servicesLoader.servicesLoading && (
                <CaseFollowUps
                  patientEmail={ebpCase.patient?.email}
                  followedUp={ebpCase.followedUp}
                  services={servicesLoader.services}
                  readOnly={false}
                />
              )}
            </div> */}
        </FormFieldset>
        <FormFieldset title="Forms">
          <div>
            <p className={styles['form-header']}>Initial MYCAW</p>
            {!readonly && allowEditTypeforms && (
              <MycawInitialTypeformLink
                cancerStatus={ebpCase?.patient?.cancerStatus}
                email={ebpCase?.patient?.email}
                fullName={
                  ebpCase?.patient
                    ? `${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`
                    : undefined
                }
              />
            )}
            <SafeGuardableTypeformList
              allowEditSafeguardCheck={!readonly && allowEditTypeforms}
              formType={EbpTypeformType.MycawInitial}
              patientEmail={ebpCase?.patient?.email}
            />
          </div>
          <div>
            <p className={styles['form-header']}>Fatigue</p>
            {!readonly && allowEditTypeforms && (
              <FatigueTypeformLink
                email={ebpCase?.patient?.email}
                fullName={
                  ebpCase?.patient
                    ? `${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`
                    : undefined
                }
              />
            )}
            <SafeGuardableTypeformList
              allowEditSafeguardCheck={!readonly && allowEditTypeforms}
              formType={EbpTypeformType.Fatigue}
              patientEmail={ebpCase?.patient?.email}
            />

            {/* Work foucused typeform was divided on two: Fatigue and RTW-SE, adding the old forms if any */}
            <SafeGuardableTypeformList
              allowEditSafeguardCheck={!readonly && allowEditTypeforms}
              formType={EbpTypeformType.WorkFocused}
              patientEmail={ebpCase?.patient?.email}
              createComponent={(form, allowEditSafeguardCheck) => (
                <WorkFocusedTypeformComponent
                  form={form as WorkFocusTypeform}
                  allowEditSafeguardCheck={allowEditSafeguardCheck}
                />
              )}
            />
          </div>

          <div>
            <p className={styles['form-header']}>Follow up MYCAW</p>
            {!readonly && allowEditTypeforms && (
              <MycawFollowUpTypeformLink
                email={ebpCase?.patient?.email}
                patientFullName={
                  ebpCase?.patient
                    ? `${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`
                    : undefined
                }
                mycawInitialFormId={ebpCase?.forms?.mycawInitialFormId}
              />
            )}
            <MycawFollowUpTypeformList
              mycawInitialFormId={ebpCase?.forms?.mycawInitialFormId}
              patientEmail={ebpCase?.patient?.email}
            />
          </div>

          {ebpCase.organization.id === OrganizationId.landg && (
            <div>
              <p className={styles['form-header']}>RTW SE</p>
              {!readonly && allowEditTypeforms && (
                <RtwSeTypeformLink
                  email={ebpCase?.patient?.email}
                  fullName={
                    ebpCase?.patient
                      ? `${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`
                      : undefined
                  }
                />
              )}
              <SafeGuardableTypeformList
                allowEditSafeguardCheck={!readonly && allowEditTypeforms}
                formType={EbpTypeformType.RtwSe}
                patientEmail={ebpCase?.patient?.email}
                createComponent={(form, allowEditSafeguardCheck) => (
                  <RtwSeTypeformComponent
                    form={form as RtwSeTypeform}
                    allowEditSafeguardCheck={allowEditSafeguardCheck}
                  />
                )}
              />

              {/* Work foucused typeform was divided on two: Fatigue and RTW-SE, adding the old forms if any */}
              <SafeGuardableTypeformList
                allowEditSafeguardCheck={!readonly && allowEditTypeforms}
                formType={EbpTypeformType.WorkFocused}
                patientEmail={ebpCase?.patient?.email}
                createComponent={(form, allowEditSafeguardCheck) => (
                  <WorkFocusedTypeformComponent
                    form={form as WorkFocusTypeform}
                    allowEditSafeguardCheck={allowEditSafeguardCheck}
                  />
                )}
              />
            </div>
          )}

          {ebpCase.organization.id === OrganizationId.landg && (
            <div>
              <p className={styles['form-header']}>MDT feedback</p>
              {!readonly && allowEditTypeforms && (
                <MdtFeedbackTypeformLink
                  email={ebpCase?.patient?.email}
                  fullName={
                    ebpCase?.patient
                      ? `${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`
                      : undefined
                  }
                />
              )}
              <MdtFeedbacTypeformList patientEmail={ebpCase?.patient?.email} />
            </div>
          )}

          {ebpCase.organization.id === OrganizationId.landg && (
            <div className="full-width">
              <CaseImprovedConcern
                caseId={ebpCase.id}
                improvedKeyChallenge={ebpCase.improvedKeyChallenge}
              />
              <CaseReturnToWork
                caseId={ebpCase.id}
                returnedToWork={ebpCase.returnedToWork}
              />
            </div>
          )}
        </FormFieldset>
        <FormFieldset title="Documents">
          <EbpDocumentsTable
            ebpCase={ebpCase}
            caseId={id}
            organizationId={ebpCase.organization.id}
            allowEditPdfs={!readonly && allowEditPdfs}
            allowUploadPdfs={!readonly}
            documentsLoader={documentsLoader}
          />
        </FormFieldset>
        {(ebpCase.organization.id === OrganizationId.healix ||
          ebpCase.organization.id === OrganizationId.landg) && (
          <FormFieldset title="Sharing activity">
            <EmailShareActivity
              caseId={ebpCase.patient?.email}
              documentsLoader={documentsLoader}
              readonly={readonly}
            />
          </FormFieldset>
        )}
      </div>
    </>
  );
}
