import {
  MycawFollowUpTypeformData,
  MycawInitialTypeformData,
} from '@packages/core-shared';

const fieldRegEx = new RegExp(/{{.*}}/);

export const getCoercedTypeformFieldTitle = (
  title: string,
  form: MycawFollowUpTypeformData | MycawInitialTypeformData,
) => {
  const fieldMatches = fieldRegEx.exec(title);
  let coercedTitle = title;
  if (fieldMatches?.length && fieldMatches.length > 0) {
    for (const m of fieldMatches) {
      if (m.includes('field:')) {
        const fieldRef = m.replace('{{field:', '').replace('}}', '');

        const fieldMetadata = Object.keys(form)
          .map((key) => form[key])
          .find((f) => f.ref === fieldRef);

        if (fieldMetadata) {
          coercedTitle = coercedTitle.replace(
            `{{field:${fieldRef}}}`,
            fieldMetadata.title,
          );
        }
      } else if (m.includes('hidden:')) {
        const hiddenFieldRef = m.replace('{{hidden:', '').replace('}}', '');

        const hiddenFieldKey = `hidden_${hiddenFieldRef}`;

        const hiddenFieldValue = (form[hiddenFieldKey] as any) ?? '';

        if (hiddenFieldValue) {
          coercedTitle = coercedTitle.replace(
            `{{hidden:${hiddenFieldRef}}}`,
            hiddenFieldValue,
          );
        }
      }
    }
  }

  // remove prefix start symbol in required fields (it is duplicated at the end)
  if (coercedTitle.length >= 1 && coercedTitle[0] === '*') {
    coercedTitle = coercedTitle.substring(1);
  }

  return coercedTitle;
};
