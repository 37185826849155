import { FormLabel } from '@percihealth/react';
import {
  MycawFollowUpTypeform,
  MycawInitialTypeform,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { getOrderedTypeformFields } from '../../getOrderedTypeformFields';
import { getCoercedTypeformFieldTitle } from '../../getCoercedTypeformFieldTitle';
import { MycawFollowUpTypeformAnswer } from './MycawFollowUpTypeformAnswer';
import styles from './MycawFollowUpTypeformComponent.module.scss';

interface Props {
  followUpForm: MycawFollowUpTypeform;
  latestInitialForm: MycawInitialTypeform | null;
}

export default function MycawFollowUpTypeformComponent({
  followUpForm,
  latestInitialForm,
}: Props) {
  return (
    <div className={styles.container}>
      <div>
        <FormLabel bold>Time to complete</FormLabel>
        <span>{followUpForm.time_to_complete}</span>
      </div>
      <div>
        <FormLabel bold>Created</FormLabel>
        <span>{dateTimeToLocalDateTimeString(followUpForm.createdAt)}</span>
      </div>
      <div className="full-width">
        <div>
          <FormLabel bold>Typeform responses</FormLabel>
          <ol>
            {getOrderedTypeformFields(followUpForm).map((field) => (
              <li key={field.ref} className={styles.qa}>
                {getCoercedTypeformFieldTitle(field.title, followUpForm)}
                <MycawFollowUpTypeformAnswer
                  fieldRef={field.ref}
                  value={field.value}
                  latestInitialForm={latestInitialForm}
                />
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}
