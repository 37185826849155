import {
  CareDocumentOutcome,
  EbpCase,
  MycawFollowUpTypeform,
  MycawFollowUpTypeformHelper,
} from '@packages/core-shared';
import { useEffect, useState } from 'react';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';

export const useGetMostImportantAspectOfSupport = (ebpCase: EbpCase | null) => {
  const [mostImportantAspectOfSupport, setMostImportantAspectOfSupport] =
    useState<CareDocumentOutcome | undefined>(undefined);

  const { record: mycawFollowUpForm } =
    useGetFirestoreDocument<MycawFollowUpTypeform>(
      'forms',
      ebpCase?.forms?.mycawFollowUpFormId,
    );

  useEffect(() => {
    const followupTypeformHelper = new MycawFollowUpTypeformHelper(
      mycawFollowUpForm,
    );

    const mostImportantSupportAspectValue =
      followupTypeformHelper.getMostImportantSupportAspect();
    setMostImportantAspectOfSupport(
      mostImportantSupportAspectValue
        ? {
            initial: 'N/A baseline',
            followUp: mostImportantSupportAspectValue,
            change: 'N/a',
          }
        : undefined,
    );
  }, [mycawFollowUpForm]);

  return mostImportantAspectOfSupport;
};
