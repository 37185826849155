import {
  FatigueTypeformData,
  FatigueTypeformFieldRef,
  TypeformField,
} from '../schemas';

export class FatigueTypeformHelper {
  constructor(private readonly form?: FatigueTypeformData | null) {}

  getFormValue = (ref?: string) => {
    if (!this.form) {
      return undefined;
    }

    const field = Object.values(this.form).find(
      (field) => (field as TypeformField | undefined)?.ref === ref,
    ) as TypeformField | undefined;

    return field?.value;
  };

  getFatigue() {
    return this.getFormValue(FatigueTypeformFieldRef.Fatigue);
  }
}
