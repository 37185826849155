import {
  MycawInitialTypeform,
  MycawFollowUpTypeformFieldRef,
  MycawInitialTypeformFieldRef,
} from '@packages/core-shared';

export const getInitialValue = (
  followUpRef: string,
  latestInitialForm: MycawInitialTypeform | null,
) => {
  if (!latestInitialForm) {
    return undefined;
  }

  const findInitialValueByRef = (ref: string) => {
    return Object.keys(latestInitialForm)
      .map((key) => latestInitialForm[key])
      .find((f) => f.ref === ref)?.value;
  };

  if (followUpRef === MycawFollowUpTypeformFieldRef.Concern1Level) {
    return findInitialValueByRef(MycawInitialTypeformFieldRef.Concern1Level);
  } else if (followUpRef === MycawFollowUpTypeformFieldRef.Concern2Level) {
    return findInitialValueByRef(MycawInitialTypeformFieldRef.Concern2Level);
  } else if (followUpRef === MycawFollowUpTypeformFieldRef.FeelingLevel) {
    return findInitialValueByRef(MycawInitialTypeformFieldRef.FeelingLevel);
  }

  return undefined;
};
