import {
  MycawFollowUpTypeformFieldRef,
  MycawFollowUpTypeformData,
  TypeformField,
} from '../schemas';

export class MycawFollowUpTypeformHelper {
  constructor(private readonly form?: MycawFollowUpTypeformData | null) {}

  getFormValue = (ref?: string) => {
    if (!this.form) {
      return null;
    }

    const field = Object.values(this.form).find(
      (field) => (field as TypeformField | undefined)?.ref === ref,
    ) as TypeformField | undefined;

    return field?.value ?? null;
  };

  getConcern1Level() {
    return this.getFormValue(MycawFollowUpTypeformFieldRef.Concern1Level);
  }
  getConcern2Level() {
    return this.getFormValue(MycawFollowUpTypeformFieldRef.Concern2Level);
  }

  getFeelingLevel() {
    return this.getFormValue(MycawFollowUpTypeformFieldRef.FeelingLevel);
  }

  getOtherFactors() {
    return this.getFormValue(MycawFollowUpTypeformFieldRef.OtherAspect);
  }

  getMostImportantSupportAspect() {
    return this.getFormValue(
      MycawFollowUpTypeformFieldRef.MostImportantSupportAspect,
    );
  }
}
