import { Link } from 'react-router-dom';
import styles from './RtwSeTypeformLink.module.scss';

interface Props {
  email?: string;
  fullName?: string;
}

export const RtwSeTypeformLink = ({ email, fullName }: Props) => {
  // const [option, setOption] = useState(Options.Wellbeing);

  if (!email || !fullName) {
    return <></>;
  }

  const formId = process.env.REACT_APP_RTW_SE_FORM_ID;

  const link = `https://percihealth.typeform.com/to/${formId}#email=${encodeURIComponent(
    email,
  )}&name=${encodeURIComponent(fullName)}&source=manual`;

  return (
    <div className={styles.container}>
      <Link to={link} target="_blank">
        {`Add "RTW SE" form`}
      </Link>
    </div>
  );
};
