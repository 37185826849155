import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import debounce from 'lodash/debounce';
import styles from './CreateEndOfCareSummaryPage.module.scss';
import {
  EbpCase,
  CareDocumentType,
  ReferralLandg,
  EndOfCareSummaryDataSchema,
  displayEbpDocumentType,
  EndOfCareSummaryData,
  Referral,
  ReferralType,
  getFullname,
  earliestDayTime,
  deepPartialOrNullable,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikDatepicker,
  FormikInputText,
  FormikTextArea,
  Spinner,
} from '@percihealth/react';
import { useParams } from 'react-router-dom';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { CareDocumentsApiRepository } from '@packages/web-shared/api/care-documents';
import { getDownloadURL, ref } from 'firebase/storage';
import DocumentTreatmentDetailsList from '../components/DocumentTreatmentDetailsList';
import ReturnToWorkStatus from './ReturnToWorkStatus';
import ClinicalExperienceAndOutcomes from '../components/ClinicalExperienceAndOutcomes';
import { useGetTypeformChanges } from '../typeforms/useGetTypeformChanges';
import ProfessionalsSeen from './ProfessionalsSeen/ProfessionalsSeen';
import DocumentResourceList from '../components/DocumentResourceList';
import { PdfGeneratedModal } from '../components/PdfGeneratedModal';
import populateClinicalAndExperienceOutcomes from '../populateClinicalAndExperienceOutcomes';
import {
  useLoadProfessionalsSeen,
  useLoadServices,
} from '@packages/web-shared/api';
import { useGetFirstAppointmentWithCns } from '../useGetFirstAppointmentWithCns';
import { useFirebase } from '@packages/web-shared';
import { useLoadInitialAssessmentSummary } from '../initialAssessmentSummary/useLoadInitialAssessmentSummary';
import { useLoadWorkFocusedPlan } from '../workFocusedPlan/useLoadWorkFocusedPlan';
import { useLoadInterimCareSummary } from '../interimCareSummary/useLoadInterimCareSummary';
import {
  useLoadEndOfCareSummary,
  useLoadEndOfCareSummaryAutosaved,
} from './useLoadEndOfCareSummary';

export default function CreateEndOfCareSummaryPage() {
  const params = useParams();
  const caseId = params?.caseId ?? null;

  if (!caseId) {
    return <div>Case ID (email) should be specified</div>;
  }

  const { storage, auth } = useFirebase();

  const [openPdfModal, setOpenPdfModal] = useState(false);
  useEffect(() => {
    setOpenPdfModal(false);
  }, [caseId]);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useGetFirestoreDocument<EbpCase>('cases', caseId);

  const docLoader = useLoadEndOfCareSummary(
    ebpCase?.documents?.endOfCareSummary?.id,
  );
  const docAutosavedLoader = useLoadEndOfCareSummaryAutosaved(caseId);
  const workFocusedPlanLoader = useLoadWorkFocusedPlan(
    ebpCase?.documents?.workFocusedPlan?.id,
  );
  const interimCareSummaryLoader = useLoadInterimCareSummary(ebpCase);
  const initialAssessmentSummaryLoader =
    useLoadInitialAssessmentSummary(ebpCase);

  const [autosaveError, setAutosaveError] = useState<string | null>(null);
  useEffect(() => {
    setAutosaveError(null);
  }, [caseId]);

  const autoSave = async (values: any) => {
    console.log('autosave');
    setAutosaveError(null);
    try {
      values.caseId = caseId;
      await docsApiRepository.autosaveEndOfCareSummaryForm(values);
    } catch (error: unknown) {
      console.error(error);
      setAutosaveError('Failed to autosave');
    }
  };
  const debouncedAutosave = useRef(debounce(autoSave, 2000));

  const servicesLoader = useLoadServices();

  const professionalsSeenLoader = useLoadProfessionalsSeen({
    patientEmail: ebpCase?.patient?.email,
    recommendedServices:
      interimCareSummaryLoader.record?.supportProgress?.map((sup) =>
        Number(sup.service.id),
      ) ?? [],
  });

  const firstAppointmentWithCns = useGetFirstAppointmentWithCns(
    ebpCase?.patient?.email,
  );

  const [createResponseError, setCreateResponseError] = useState<string | null>(
    null,
  );

  const handleOnSubmit = async (
    values: EndOfCareSummaryData,
    actions: FormikHelpers<EndOfCareSummaryData>,
  ) => {
    try {
      actions.setSubmitting(true);
      setCreateResponseError(null);
      const { storageRef } =
        await docsApiRepository.createEndOfCareSummary(values);

      const previewUrl = await getDownloadURL(ref(storage, storageRef));

      setOpenPdfModal(true);

      // Open the file in a new tab, so it is not automatically downloaded to the local storage
      window.open(previewUrl, '_blank');
    } catch (error: unknown) {
      let errorMsg = '';

      if (error instanceof Error) {
        errorMsg = error.message;
      } else {
        errorMsg = JSON.stringify(error);
      }

      console.error(errorMsg);
      setCreateResponseError(errorMsg);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { record: referral } = useGetFirestoreDocument<Referral>(
    'referrals',
    ebpCase?.referralId,
  );
  const gipCode = useMemo(() => {
    if (referral?.type !== ReferralType.landg) {
      return '';
    }

    return (referral as ReferralLandg)?.gip?.code ?? '';
  }, [referral]);

  const mycawLoader = useGetTypeformChanges({
    ebpCase: ebpCase,
  });

  const formikDependencies = [
    docLoader.record,
    docAutosavedLoader.record,
    workFocusedPlanLoader.record,
    initialAssessmentSummaryLoader.record,
    interimCareSummaryLoader.record,
    mycawLoader,
    ebpCase,
    gipCode,
    servicesLoader.services,
    professionalsSeenLoader.professionalsSeen,
    firstAppointmentWithCns,
  ];

  const initialValues: EndOfCareSummaryData = useMemo(() => {
    let docData = docAutosavedLoader.record ?? docLoader.record;
    if (docData) {
      // Deep clone
      try {
        // schema cast is required to have Date type instead of strings
        docData = deepPartialOrNullable(EndOfCareSummaryDataSchema).cast(
          JSON.parse(JSON.stringify(docData)),
        ) as EndOfCareSummaryData;
      } catch {
        // intentionally left blank
      }
    }
    const result =
      docData ??
      ({
        caseId,
        type: CareDocumentType.EndOfCareSummary,
        patient: {
          fullName: ebpCase?.patient ? getFullname(ebpCase.patient) : '',
          dob:
            ebpCase?.patient?.dateOfBirth ??
            interimCareSummaryLoader.record?.patient?.dob ??
            initialAssessmentSummaryLoader.record?.patient?.dob ??
            earliestDayTime(new Date()),
          cancerType:
            interimCareSummaryLoader?.record?.patient?.cancerType ??
            ebpCase?.patient?.medicalHistory?.sentToHealee?.cancerType ??
            '',
          dateOfDiagnosis:
            initialAssessmentSummaryLoader.record?.patient?.dateOfDiagnosis ??
            new Date(),
        },
        gip: {
          code: gipCode,
        },
        perciStartDate:
          interimCareSummaryLoader.record?.perciStartDate ??
          firstAppointmentWithCns?.date ??
          new Date(new Date().getFullYear(), 1, 1),
        endOfCareDate: new Date(),

        treatmentDetails: initialAssessmentSummaryLoader.record
          ?.treatmentDetails ?? {
          treatment: '',
          expectedEndDate: '',
          plan: '',
          other: 'None',
        },
        returnToWorkStatus: {
          returnToWork: { checked: true },
          intendsToReturnToWork: { checked: true },
          returnToWorkDateAgreed: { checked: true },
          caseManagerReview: { checked: true },
        },
        clinicalAndExperienceOutcomes: {
          mycawConcern1: mycawLoader.mycawConcern1 ?? {},
          mycawConcern2: mycawLoader.mycawConcern2 ?? {},
          overallWellbeing: mycawLoader.overallWellbeing ?? {},
          mostImportantAspectOfSupport:
            mycawLoader.mostImportantAspectOfSupport,
          otherFactors: mycawLoader.otherFactors,
          returnToWorkSelfEfficacy: mycawLoader.returnToWorkSelfEfficacy ?? {},
          fatigue: mycawLoader.fatigue ?? {},
        },
        // populated later
        professionalsSeen: [],
        additionalCommunicationNotes:
          'End of care summary also sent to GP and treating cancer team.',
        additionalSignPosting: [],

        expertSummary: {
          motivation: '',
          progressAgainstGoals: '',
          assessmentOfCapabilitiesReturnToWork: '',
        },
        dateOfConsultation: earliestDayTime(new Date()),
      } satisfies EndOfCareSummaryData);

    // Correct old autosaved data
    populateClinicalAndExperienceOutcomes(
      result.clinicalAndExperienceOutcomes,
      { populateOtherFactors: true },
      mycawLoader,
    );

    if (Object.keys(servicesLoader.services).length > 0) {
      result.professionalsSeen = Object.keys(
        professionalsSeenLoader.professionalsSeen,
      ).map((serviceId) => ({
        service: {
          id: serviceId,
          name: servicesLoader.services[serviceId]?.name,
          professionalType:
            servicesLoader.services[serviceId]?.professional_type,
        },
        appointments: professionalsSeenLoader.professionalsSeen[serviceId]?.map(
          (app) => ({
            id: app.id,
            date: app.date ?? undefined,
            professionalName: app.doctor,
          }),
        ),
      }));
    }

    return result;
  }, [...formikDependencies]);

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: EndOfCareSummaryDataSchema.isValidSync(initialValues),
    initialValues: initialValues,
    validationSchema: EndOfCareSummaryDataSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    formik.resetForm();
  }, [...formikDependencies]);

  useEffect(() => {
    if (formik?.dirty) {
      debouncedAutosave.current(formik.values);
    }
  }, [formik?.values, formik?.dirty]);

  if (caseLoading || docLoader.loading) {
    return <Spinner />;
  }

  if (caseLoadingErrorMessage || !ebpCase) {
    return (
      <AlertError>{caseLoadingErrorMessage ?? 'Not found case'}</AlertError>
    );
  }

  if (docLoader.errorMessage) {
    return <AlertError>{docLoader.errorMessage}</AlertError>;
  }
  if (docAutosavedLoader.errorMessage) {
    return <AlertError>{docAutosavedLoader.errorMessage}</AlertError>;
  }
  if (workFocusedPlanLoader.errorMessage) {
    return <AlertError>{workFocusedPlanLoader.errorMessage}</AlertError>;
  }
  if (initialAssessmentSummaryLoader.errorMessage) {
    return (
      <AlertError>{initialAssessmentSummaryLoader.errorMessage}</AlertError>
    );
  }
  if (interimCareSummaryLoader.errorMessage) {
    return <AlertError>{interimCareSummaryLoader.errorMessage}</AlertError>;
  }

  return (
    <FormikProvider value={formik}>
      {autosaveError && <AlertError>{autosaveError}</AlertError>}
      <div className={styles.pcp}>
        <Form onSubmit={formik.handleSubmit}>
          <FormFieldset title="Patient's details">
            <FormikInputText
              id="patient.fullName"
              name="patient.fullName"
              required
              label="Patient full name"
              value={formik.values.patient.fullName}
            />
            <FormikDatepicker
              id="patient.dob"
              name="patient.dob"
              required
              label="Patient DOB"
              className="w-full"
              value={formik.values.patient.dob}
            />
            <FormikInputText
              id="patient.cancerType"
              name="patient.cancerType"
              required
              label="Cancer type"
              value={formik.values.patient.cancerType}
            />
            <FormikDatepicker
              id="patient.dateOfDiagnosis"
              name="patient.dateOfDiagnosis"
              required
              label="Date of diagnosis"
              value={formik.values.patient.dateOfDiagnosis}
            />
          </FormFieldset>
          <FormFieldset title="Care details">
            <FormikDatepicker
              id="perciStartDate"
              name="perciStartDate"
              required
              label="First appointment date with CNS"
              value={formik.values.perciStartDate}
            />
            <FormikDatepicker
              id="endOfCareDate"
              name="endOfCareDate"
              required
              label="End of care date"
              value={formik.values.endOfCareDate}
            />
            <FormikDatepicker
              id="dateOfConsultation"
              name="dateOfConsultation"
              required
              label="Date of consultation"
              value={formik.values.dateOfConsultation}
            />
            <FormikInputText
              id="gip.code"
              name="gip.code"
              required
              disabled={!!gipCode}
              label="Group income protection code"
              value={formik.values.gip.code}
            />
          </FormFieldset>

          <DocumentTreatmentDetailsList formik={formik} />
          <ReturnToWorkStatus formik={formik} />

          <FormFieldset title="Cancer nurse specialist summary">
            <FormikTextArea
              className="full-width"
              name="expertSummary.motivation"
              rows={4}
              label="Motivation and programme engagement"
              required
              value={formik.values.expertSummary.motivation}
            />
            <FormikTextArea
              className="full-width"
              name="expertSummary.progressAgainstGoals"
              rows={4}
              label="Progress against goals and symptom/problem management"
              required
              value={formik.values.expertSummary.progressAgainstGoals}
            />
            <FormikTextArea
              className="full-width"
              name="expertSummary.assessmentOfCapabilitiesReturnToWork"
              rows={4}
              label="Assessment of capability to return back to work"
              required
              value={
                formik.values.expertSummary.assessmentOfCapabilitiesReturnToWork
              }
            />
          </FormFieldset>

          <FormFieldset title="Clinical outcomes">
            <ClinicalExperienceAndOutcomes
              clinicalAndExperienceOutcomes={
                formik.values.clinicalAndExperienceOutcomes
              }
            />
          </FormFieldset>

          <FormFieldset title="Perci professionals seen">
            {professionalsSeenLoader.loading && <Spinner />}
            {professionalsSeenLoader.errorMessage && (
              <AlertError>{professionalsSeenLoader.errorMessage}</AlertError>
            )}
            {servicesLoader.servicesError && (
              <AlertError>{servicesLoader.servicesError}</AlertError>
            )}
            {servicesLoader.servicesLoading && <Spinner />}
            {!professionalsSeenLoader.loading && (
              <ProfessionalsSeen
                values={formik.values.professionalsSeen}
                onChange={(arr) =>
                  formik.setFieldValue('professionalsSeen', arr, true)
                }
              />
            )}
          </FormFieldset>

          <FormFieldset title="Additional signposting provided since last assessment">
            <DocumentResourceList
              resources={formik.values.additionalSignPosting}
              onChange={(updated) => {
                formik.setFieldValue('additionalSignPosting', updated);
              }}
            />
          </FormFieldset>

          <FormFieldset title="Additional communication sent to non-Perci Healthcare team">
            <FormikTextArea
              rows={3}
              name="additionalCommunicationNotes"
              className="full-width"
              value={formik.values.additionalCommunicationNotes}
            />
          </FormFieldset>

          <hr />
          <div className={styles.full}>
            <Button
              type="submit"
              className={styles.submit}
              fullWidth
              disabled={!formik.isValid}
              submitting={formik.isSubmitting}
            >
              Create {displayEbpDocumentType(CareDocumentType.EndOfCareSummary)}
            </Button>
          </div>
          {createResponseError && (
            <AlertError>{createResponseError}</AlertError>
          )}
        </Form>
      </div>
      <PdfGeneratedModal
        caseId={caseId}
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
      />
    </FormikProvider>
  );
}
