import { useCallback, useMemo, useState } from 'react';
import { useFirebase } from '../../context';
import { CasesApiRepository } from './CasesApiRepository';

export const useUpdateExpertId = () => {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { auth } = useFirebase();
  const casesApiRepository = useMemo(
    () => new CasesApiRepository(auth),
    [auth],
  );

  const update = useCallback(async (caseId: string, expertId: string) => {
    try {
      setUpdating(true);
      setErrorMessage(null);
      await casesApiRepository.updateExpertId(caseId, expertId);
    } catch (err) {
      console.error(err);
      setErrorMessage(err instanceof Error ? err.message : JSON.stringify(err));
    } finally {
      setUpdating(false);
    }
  }, []);

  return { update, updating, errorMessage };
};
