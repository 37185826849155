import { useSubscribeTypeforms } from '../../../../../../../api';
import { AlertError, Spinner } from '@percihealth/react';
import CollapsibleContainer from '../../CollapsibleContainer';
import SafeGuardableTypeformComponent from '../SafeGuardableTypeformComponent';
import {
  EbpTypeform,
  EbpTypeformType,
  MycawInitialTypeform,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { default as CheckmarkSvg } from './checkmark.svg';
import { default as WarningSvg } from './warning.svg';
import styles from './SafeGuardableTypeformList.module.scss';
import { useCallback } from 'react';

interface Props {
  patientEmail?: string;
  allowEditSafeguardCheck: boolean;
  formType: EbpTypeformType;
  createComponent?: (
    form: EbpTypeform,
    allowEditSafeguardCheck: boolean,
  ) => React.ReactNode;
}

export default function SafeGuardableTypeformList({
  patientEmail,
  formType,
  allowEditSafeguardCheck,
  createComponent,
}: Props) {
  const formsLoader = useSubscribeTypeforms<EbpTypeform>({
    patientEmail,
    formType,
  });

  const createFn = useCallback(
    (form: EbpTypeform, allowEditSafeguardCheck: boolean) => {
      if (createComponent) {
        return createComponent(form, allowEditSafeguardCheck);
      }

      return (
        <SafeGuardableTypeformComponent
          form={form}
          allowEditSafeguardCheck={allowEditSafeguardCheck}
        />
      );
    },
    [createComponent],
  );

  if (formsLoader.loading) {
    return <Spinner />;
  }

  if (formsLoader.errorMessage) {
    return <AlertError>{formsLoader.errorMessage}</AlertError>;
  }

  const getFormName = (form: MycawInitialTypeform) => (
    <>
      {`${dateTimeToLocalDateTimeString(form.createdAt)}`}
      <img
        className={styles['status-icon']}
        src={form.safeguardCheck ? CheckmarkSvg : WarningSvg}
      />
    </>
  );

  return (
    <div className={styles.container}>
      {formsLoader.records.map((form) => (
        <CollapsibleContainer key={form.id} name={getFormName(form)}>
          {createFn(form, allowEditSafeguardCheck)}
        </CollapsibleContainer>
      ))}
    </div>
  );
}
