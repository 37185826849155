import styles from './AppointmentsHistory.module.css';
import {
  AppointmentData,
  AppointmentStatus,
  dateTimeToLondonDateTimeString,
  OrganizationId,
  ServiceInfo,
} from '@packages/core-shared';
import { AlertError, classList, Spinner } from '@percihealth/react';
import { useLoadPatientAppointments } from '../../../api';
import { useCallback, useMemo } from 'react';
import AppointmentConclusionControl from './AppointmentConclusionControl';

interface Props {
  patientEmail?: string;
  organizationId?: string;
  services: Record<string, ServiceInfo>;
  disabled: boolean;
}
export default function AppointmentsHistory({
  patientEmail,
  organizationId,
  services,
  disabled,
}: Props) {
  const appointmentsLoader = useLoadPatientAppointments({
    patientEmail,
  });

  const isLandg = useMemo(
    () => organizationId === OrganizationId.landg,
    [organizationId],
  );

  const getPreviousAppointmentDates = useCallback(
    (appointment: AppointmentData) => {
      const result = [...(appointment.previousAppointmentDates ?? [])];
      result.sort(
        (a, b) => (a.date?.getTime() ?? -1) - (b.date?.getTime() ?? -1),
      );

      return result;
    },
    [],
  );

  const getAppointmentStatus = useCallback((appointment: AppointmentData) => {
    if (appointment.status === AppointmentStatus.completed) {
      return appointment.noShow ? 'missed' : 'attended';
    } else if (
      appointment.status === AppointmentStatus.booked &&
      appointment.previousAppointmentDates?.length
    ) {
      return AppointmentStatus.rescheduled;
    } else {
      return appointment.status;
    }
  }, []);

  return (
    <div className={classList(styles.container, 'full-width')}>
      {appointmentsLoader.loading && <Spinner />}
      {appointmentsLoader.errorMessage && (
        <AlertError>{appointmentsLoader.errorMessage}</AlertError>
      )}
      {!appointmentsLoader.loading && (
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>App. ID</th>
              <th>Service(s)</th>
              <th>Doctor</th>
              <th>{'Date & Time (London)'}</th>
              <th>Status</th>
              {isLandg && <th>Conclusion</th>}
            </tr>
          </thead>
          <tbody>
            {appointmentsLoader.records.map((appointment) => (
              <tr key={appointment.id}>
                <td>
                  <span>{appointment.id}</span>
                </td>
                <td>
                  <span>
                    {(appointment.services ?? [])
                      .map(
                        (serviceId) =>
                          services[serviceId?.toString() ?? '']?.name ?? '?',
                      )
                      .join(', ')}
                  </span>
                </td>
                <td>
                  <span>{appointment.doctor}</span>
                </td>
                <td>
                  {getPreviousAppointmentDates(appointment).map((prevApp) => (
                    <div
                      key={prevApp.date?.toISOString() ?? ''}
                      style={{ paddingBottom: '8px' }}
                    >
                      <div style={{ textDecoration: 'line-through' }}>
                        {dateTimeToLondonDateTimeString(prevApp.date)}
                      </div>
                      <div style={{ fontSize: '13px', fontStyle: 'italic' }}>
                        Rescheduled on{' '}
                        {dateTimeToLondonDateTimeString(prevApp.rescheduled)} by{' '}
                        {prevApp.by}
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      paddingBottom: '8px',
                      textDecoration:
                        appointment.status === AppointmentStatus.cancelled
                          ? 'line-through'
                          : undefined,
                    }}
                  >
                    {dateTimeToLondonDateTimeString(appointment.date)}
                  </div>
                  {appointment.status === AppointmentStatus.cancelled && (
                    <div
                      style={{
                        fontSize: '13px',
                        fontStyle: 'italic',
                        paddingBottom: '4px',
                      }}
                    >
                      Cancelled at{' '}
                      <span>
                        {dateTimeToLondonDateTimeString(
                          appointment.cancelledAt,
                        )}
                      </span>
                    </div>
                  )}
                </td>
                <td>
                  <span>{getAppointmentStatus(appointment)}</span>
                </td>
                {isLandg && (
                  <td>
                    {appointment.conclusion && (
                      <AppointmentConclusionControl
                        appointmentId={appointment.id}
                        services={services}
                        conclusion={appointment.conclusion}
                        disabled={disabled}
                      />
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
