import { useEffect, useMemo, useState } from 'react';
import styles from './InitialAssessmentRecommendedSupportTypes.module.css';
import { useLoadProfessionals } from '@packages/web-shared/api';
import {
  AlertError,
  Button,
  DropdownSearch,
  InputText,
  Spinner,
} from '@percihealth/react';
import {
  Appointment,
  AppointmentStatus,
  InitialAssessmentSummaryDataReferredProfessional,
  InitialAssessmentSummaryDataReferredProfessionalListSchema,
  PcpPatientService,
  RecommendedSupportStatus,
  ServiceInfo,
} from '@packages/core-shared';
import _ from 'lodash';
import { ValidationError } from 'yup';

interface Props {
  values: InitialAssessmentSummaryDataReferredProfessional[];
  services: Record<string, ServiceInfo>;
  onChange: (arr: InitialAssessmentSummaryDataReferredProfessional[]) => void;
}

const EmptyOption = {
  value: '',
  name: '-',
};

export default function InitialAssessmentRecommendedSupportTypes({
  values,
  services,
  onChange,
}: Props) {
  const [validationsErrors, setValidationErrors] = useState<string | null>(
    null,
  );

  // Bug in formik, it does not show formik.errors for arrays
  // https://github.com/jaredpalmer/formik/issues/1116
  // Use yup validation
  useEffect(() => {
    try {
      InitialAssessmentSummaryDataReferredProfessionalListSchema.validateSync(
        values,
      );
      setValidationErrors(null);
    } catch (err: unknown) {
      const validationError = err as ValidationError;
      const errors = validationError.errors
        // unique
        .filter((value, index, array) => array.indexOf(value) === index);
      setValidationErrors(errors.join(', '));
    }
  }, [values]);

  const [serviceKeyToAdd, setServiceKeyToAdd] = useState<string | null>(
    Object.keys(services).length > 0 ? Object.keys(services)[0] : null,
  );

  const serviceOptions = useMemo(() => {
    return Object.keys(services).map((key) => ({
      value: key,
      name: services[key].name,
    }));
  }, [services]);

  const professionalsLoader = useLoadProfessionals();

  const add = () => {
    if (!serviceKeyToAdd) {
      return;
    }

    const arr = [...values];
    arr.push({
      service: {
        id: serviceKeyToAdd,
        name: services[serviceKeyToAdd].name,
        professionalType: services[serviceKeyToAdd].professional_type,
      },
      concern: '',
      status: RecommendedSupportStatus.pending,
    });
    onChange(arr);
  };

  const remove = (index: number) => {
    onChange(values.filter((_, idx) => idx !== index));
  };

  return (
    <div className="full-width">
      {professionalsLoader.errorMessage && (
        <AlertError>{professionalsLoader.errorMessage}</AlertError>
      )}
      {professionalsLoader.loading && <Spinner className={styles.spinner} />}
      <p className="description">
        Based on your personal story and everything you have shared with your
        dedicated nurse, they have suggested that the following support types
        could be beneficial.
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Support type</th>
            <th>Related member concern/impact</th>
            <th>Referred to</th>
            <th>Status</th>
            <th>Referred in PCP</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {values.map((pr, index) => (
            <tr key={index}>
              <td>{pr.service.name}</td>
              <td>
                <InputText
                  value={pr.concern}
                  onChange={(e) => {
                    const arr = [...values];
                    arr[index] = { ...arr[index], concern: e.target.value };
                    onChange(arr);
                  }}
                />
              </td>
              <td style={{ minWidth: 300 }}>
                {pr.appointmentId && pr.professional?.name ? (
                  pr.professional.name
                ) : (
                  <DropdownSearch
                    multiple={false}
                    autoComplete="on"
                    value={pr.professional?.id ?? ''}
                    options={[
                      EmptyOption,
                      ...professionalsLoader.records
                        .filter((prof) => prof.services.includes(pr.service.id))
                        .map((prof) => ({
                          value: prof.id,
                          name: prof.fullname,
                        })),
                    ]}
                    onChange={(val) => {
                      const professionalId = val as string;
                      pr.professional = {
                        id: professionalId,
                        name:
                          professionalsLoader.records.find(
                            (prof) => prof.id === professionalId,
                          )?.fullname ?? '',
                      };
                      onChange(values);
                    }}
                  />
                )}
              </td>
              <td>{pr.status}</td>
              <td>{pr.referredEarlier ? 'Yes' : 'No'}</td>
              <td>
                <a onClick={() => remove(index)}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles['add-container']}>
        <div className={styles.services}>
          <DropdownSearch
            multiple={false}
            autoComplete="on"
            value={serviceKeyToAdd ?? ''}
            options={serviceOptions}
            onChange={(val) => {
              setServiceKeyToAdd(val as string);
            }}
          />
        </div>
        <Button type="button" className={styles.add} onClick={add}>
          Add
        </Button>
      </div>
      {validationsErrors && (
        <div style={{ marginTop: 24 }}>
          <AlertError>{validationsErrors}</AlertError>
        </div>
      )}
    </div>
  );
}

export const populateReferredServicesWithAppointnments = (
  savedProfessionalReferred: InitialAssessmentSummaryDataReferredProfessional[],
  services: Record<string, ServiceInfo>,
  serviceIdAppointmentsMap: Record<string, Appointment[]>,
  pcpPatientServices: PcpPatientService[],
) => {
  // populate missed services
  const results = [...savedProfessionalReferred];

  // from PCP
  for (const pcpService of pcpPatientServices.filter(
    (pcp) => !!pcp.service.id,
  )) {
    if (!results.some((res) => res.service.id === pcpService.service.id)) {
      results.push({
        service: {
          id: pcpService.service.id,
          name: pcpService.service.name,
          professionalType: services[pcpService.service.id]?.professional_type,
        },
        concern: pcpService.concerns?.join(', ') ?? '',
        // status will be corrected later
        status: RecommendedSupportStatus.pending,
      });
    }
  }

  // From booked and attended appointments
  for (const serviceId in serviceIdAppointmentsMap) {
    if (!results.some((ps) => ps.service?.id === serviceId)) {
      results.push({
        service: {
          id: serviceId,
          name: services[serviceId]?.name,
          professionalType: services[serviceId]?.professional_type,
        },
        concern: '',
        status: RecommendedSupportStatus.pending,
      });
    }
  }

  // referredEarlier
  for (const pr of results) {
    pr.referredEarlier = pcpPatientServices.some(
      (ps) => ps.service?.id && ps.service.id === pr.service.id,
    );
  }

  // Collect statuses and professionals
  // Appointmetns are orderd in the desceneding order by "date"

  // Service 1 with the "booked" status
  //  - BOOKED 20 AUG
  //  - BOOKED 21 AUG
  // Result: take the first => 20 AUG

  // Service 1 with the "attended" status
  // - ATTENDED 1 JUL
  // - ATTENDED 2 JUL
  // Result: take the last => 2 JUL

  for (const profServiceRow of results) {
    const appointments =
      serviceIdAppointmentsMap[profServiceRow.service.id] ?? [];
    const appointment =
      appointments.findLast(
        (app) => app.status === AppointmentStatus.completed,
      ) ??
      appointments.find((app) =>
        [AppointmentStatus.booked, AppointmentStatus.rescheduled].includes(
          app.status,
        ),
      );

    // If there is an appointment, the professional can not be changed
    if (appointment) {
      profServiceRow.professional = {
        name: appointment.doctor ?? '',
      };
      profServiceRow.appointmentId = appointment.id;
      profServiceRow.status =
        appointment.status === AppointmentStatus.completed
          ? RecommendedSupportStatus.attended
          : RecommendedSupportStatus.booked;
    } else {
      profServiceRow.appointmentId = undefined;
      profServiceRow.status = RecommendedSupportStatus.pending;

      if (!profServiceRow.professional)
        profServiceRow.professional = pcpPatientServices.find(
          (ps) => ps.service?.id === profServiceRow.service.id,
        )?.professional;
    }

    // correct empty concerns
    if (pcpPatientServices && pcpPatientServices?.length > 0) {
      for (const profRef of results.filter((pr) => !pr.concern)) {
        profRef.concern =
          pcpPatientServices
            .find(
              (ps) => ps.service?.id && ps.service.id === profRef.service.id,
            )
            ?.concerns?.join(', ') ?? '';
      }
    }
  }

  return results;
};
