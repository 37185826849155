import {
  MdtFeedbackTypeform,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { getOrderedTypeformFields } from '../../getOrderedTypeformFields';
import { getCoercedTypeformFieldTitle } from '../../getCoercedTypeformFieldTitle';
import styles from './MdtFeedbackTypeformComponent.module.scss';
import { FormLabel } from '@percihealth/react';

interface Props {
  form: MdtFeedbackTypeform;
}

export default function MdtFeedbackTypeformComponent({ form }: Props) {
  const orderedFields = getOrderedTypeformFields(form);

  return (
    <div className={styles.container}>
      <div>
        <FormLabel bold>Time to complete</FormLabel>
        <span>{form.time_to_complete}</span>
      </div>
      <div>
        <FormLabel bold>Created</FormLabel>
        <span>{dateTimeToLocalDateTimeString(form.createdAt)}</span>
      </div>
      <div className="full-width">
        <div>
          <FormLabel bold>Typeform responses</FormLabel>
          <ol>
            {orderedFields.map((field) => (
              <li key={field.ref} className={styles.qa}>
                {getCoercedTypeformFieldTitle(field.title, form)}
                <p className={styles.answer}>{field.value}</p>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}
