import { useSubscribeTypeforms } from '../../../../../../../api';
import { AlertError, Spinner } from '@percihealth/react';
import CollapsibleContainer from '../../CollapsibleContainer';

import {
  EbpTypeformType,
  MdtFeedbackTypeform,
  MdtFeedbackTypeformFieldRef,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import styles from './MdtFeedbackTypeformList.module.scss';
import MdtFeedbackTypeformComponent from '../MdtFeedbackTypeformComponent';
import { getOrderedTypeformFields } from '../../getOrderedTypeformFields';

export default function MdtFeedbacTypeformList({
  patientEmail,
}: {
  patientEmail?: string;
}) {
  const formsLoader = useSubscribeTypeforms<MdtFeedbackTypeform>({
    patientEmail,
    formType: EbpTypeformType.MdtFeedback,
  });

  if (formsLoader.loading) {
    return <Spinner />;
  }

  if (formsLoader.errorMessage) {
    return <AlertError>{formsLoader.errorMessage}</AlertError>;
  }

  const getFormName = (form: MdtFeedbackTypeform) => {
    const orderedFields = getOrderedTypeformFields(form);

    const serviceLineField = orderedFields.find(
      (field) => field.ref === MdtFeedbackTypeformFieldRef.ServiceLine,
    );

    return `${dateTimeToLocalDateTimeString(form.createdAt)} - ${serviceLineField?.value}`;
  };

  return (
    <div className={styles.container}>
      {formsLoader.records.map((form) => (
        <CollapsibleContainer key={form.id} name={getFormName(form)}>
          <MdtFeedbackTypeformComponent form={form} />
        </CollapsibleContainer>
      ))}
    </div>
  );
}
