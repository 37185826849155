import { Link } from 'react-router-dom';
import styles from './MdtFeedbackTypeformLink.module.scss';
import { CancerStatus } from '@packages/core-shared';

interface Props {
  email?: string;
  fullName?: string;
  cancerStatus?: CancerStatus;
}

export const MdtFeedbackTypeformLink = ({ email, fullName }: Props) => {
  if (!email) {
    return <></>;
  }

  const link = `https://percihealth.typeform.com/to/${process.env.REACT_APP_MDT_FEEDBACK_FORM_ID}#email=${encodeURIComponent(
    email,
  )}&name=${encodeURIComponent(fullName ?? '')}`;

  return (
    <div className={styles.container}>
      <Link to={link} target="_blank">
        Add MDT feedback form
      </Link>
    </div>
  );
};
