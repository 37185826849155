import { useFirebase } from '@packages/web-shared';
import { AlertError, Button, Modal } from '@percihealth/react';
import { useEffect, useMemo, useState } from 'react';
import styles from './UndischargeConfirmationModal.module.css';
import { CasesApiRepository } from '../../../../../../api';

interface Props {
  caseId: string;
  patientFullname: string;
  open: boolean;
  onClose: () => void;
}

export const UndischargeConfirmationModal = ({
  caseId,
  patientFullname,
  open,
  onClose,
}: Props) => {
  const { auth } = useFirebase();
  const casesApiRepository = useMemo(
    () => new CasesApiRepository(auth),
    [auth],
  );

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setErrorMessage(null);
    setSubmitting(false);
  }, [caseId, open]);

  const cancelReferral = async () => {
    setSubmitting(true);
    setErrorMessage(null);
    try {
      await casesApiRepository.undischarge(caseId);
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} center>
      <h3>Cancellation confirmation</h3>
      <p>
        You are going to un-discharge the case for the member:{' '}
        {
          <span className={styles.patient}>
            <b>{patientFullname}</b>
          </span>
        }
        .
      </p>
      Continue?
      <br />
      <br />
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onClose} disabled={submitting}>
          Abort
        </Button>

        <Button
          level="secondary"
          submitting={submitting}
          onClick={async () => {
            await cancelReferral();
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
