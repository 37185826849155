import {
  EbpCase,
  EbpCaseExpertUpsertNote,
  EbpCaseReturnedToWork,
  HealeePatientMedicalHistory,
  UpdateEbpCase,
} from '@packages/core-shared';
import { ApiRepository } from '../../helpers/ApiRepository';
import { getFetchResponseErrorMessage } from '../../helpers';

export class CasesApiRepository extends ApiRepository {
  async updateExpertId(caseId: string, expertId: string) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({ expertId } satisfies Partial<UpdateEbpCase>),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating case expoert');
    }
  }

  async updateLevel(
    id: string,
    data: {
      patient: { level: string };
    },
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(data),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating level');
    }
  }

  async updateFollowedUpAfterPcp(caseId: string, followedUpAfterPcp: boolean) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          followedUpAfterPcp,
        } satisfies Partial<UpdateEbpCase>),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating follow up');
    }
  }

  async updateConsentShareDataToInsurer(
    caseId: string,
    consentShareDataToInsurer: boolean,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          consentShareDataToInsurer,
        } satisfies Partial<UpdateEbpCase>),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating consent');
    }
  }

  async updateImprovedKeyChallenge(
    caseId: string,
    improvedKeyChallenge: boolean | null,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          improvedKeyChallenge,
        } satisfies Partial<UpdateEbpCase>),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating improved key challenge');
    }
  }

  async updateReturnedToWork(
    caseId: string,
    returnedToWork: EbpCaseReturnedToWork | null,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          returnedToWork,
        } satisfies Partial<UpdateEbpCase>),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating the case');
    }
  }

  async addExpertInternalNotes(caseId: string, note: EbpCaseExpertUpsertNote) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/notes`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(note),
      },
    );

    if (!response.ok) {
      throw new Error('Error adding notes');
    }
  }

  async updateExpertInternalNotes(
    caseId: string,
    id: string,
    note: EbpCaseExpertUpsertNote,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/notes/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(note),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating internal notes');
    }
  }

  async saveMedicalHistory(caseId: string, body: HealeePatientMedicalHistory) {
    const token = await this.getToken();
    if (!token) {
      throw 'Error saving medical history, unauthorized';
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/medical-history/healee`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(body),
      },
    );

    if (!response.ok) {
      throw 'Error saving medical history';
    }
  }

  async discharge(
    caseId: string,
    dischargeOptions: EbpCase['dischargeOptions'],
  ) {
    const token = await this.getToken();
    if (!token) {
      throw 'Unauthorized';
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/discharge`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(dischargeOptions),
      },
    );

    if (!response.ok) {
      const errorMsg = await getFetchResponseErrorMessage(response);
      throw errorMsg;
    }
  }

  async undischarge(caseId: string) {
    const token = await this.getToken();
    if (!token) {
      throw 'Unauthorized';
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/undischarge`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({}),
      },
    );

    if (!response.ok) {
      const errorMsg = await getFetchResponseErrorMessage(response);
      throw errorMsg;
    }
  }
}
