import { useSubscribeTypeforms } from '../../../../../../../api';
import { AlertError, Spinner } from '@percihealth/react';
import CollapsibleContainer from '../../CollapsibleContainer';
import MycawFollowUpTypeformComponent from '../MycawFollowUpTypeformComponent';
import {
  EbpTypeformType,
  MycawFollowUpTypeform,
  MycawInitialTypeform,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { useGetFirestoreDocument } from '../../../../../../../hooks/useGetFirestoreDocument';
import styles from './MycawFollowUpTypeformList.module.scss';

export default function MycawFollowUpTypeformList({
  mycawInitialFormId,
  patientEmail,
}: {
  mycawInitialFormId?: string;
  patientEmail?: string;
}) {
  const formsLoader = useSubscribeTypeforms<MycawInitialTypeform>({
    patientEmail,
    formType: EbpTypeformType.MycawFollowUp,
  });

  const initialFormLoader = useGetFirestoreDocument<MycawInitialTypeform>(
    'forms',
    mycawInitialFormId,
  );

  if (formsLoader.loading || initialFormLoader.loading) {
    return <Spinner />;
  }

  if (formsLoader.errorMessage) {
    return <AlertError>{formsLoader.errorMessage}</AlertError>;
  }

  if (initialFormLoader.errorMessage) {
    return <AlertError>{initialFormLoader.errorMessage}</AlertError>;
  }

  const getFormName = (form: MycawFollowUpTypeform) =>
    `${dateTimeToLocalDateTimeString(form.createdAt)}`;

  return (
    <div className={styles.container}>
      {formsLoader.records.map((form) => (
        <CollapsibleContainer key={form.id} name={getFormName(form)}>
          <MycawFollowUpTypeformComponent
            followUpForm={form}
            latestInitialForm={initialFormLoader.record}
          />
        </CollapsibleContainer>
      ))}
    </div>
  );
}
