// John Doe => John D.
// John => John
// Doe => ? D.
export const getFirstnameAndInitialOfLastname = (props: {
  firstName?: string | null;
  lastName?: string | null;
}) => {
  if (!props.lastName) {
    return props.firstName ?? '';
  } else {
    return `${props.firstName ?? '?'} ${props.lastName[0].toUpperCase()}`;
  }
};

// John Doe => John Doe
// John => John
// Doe => Doe
export const getFullname = (props: {
  firstName?: string | null;
  lastName?: string | null;
}) => {
  if (!props.lastName) {
    return props.firstName ?? '';
  } else if (!props.firstName) {
    return props.lastName ?? '';
  } else {
    return `${props.firstName} ${props.lastName}`;
  }
};
