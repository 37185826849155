import {
  EbpCase,
  MycawInitialTypeform,
  MycawInitialTypeformHelper,
} from '@packages/core-shared';
import { useEffect, useState } from 'react';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';

export const useGetTypeformImpactsChanges = (ebpCase: EbpCase | null) => {
  const [impacts, setImpacts] = useState<{
    psychological: string[];
    physical: string[];
    affectedRelationships: string[];
  }>({ psychological: [], physical: [], affectedRelationships: [] });

  const { record: mycawInitialForm } =
    useGetFirestoreDocument<MycawInitialTypeform>(
      'forms',
      ebpCase?.forms?.mycawInitialFormId,
    );

  useEffect(() => {
    const initialTypeformHelper = new MycawInitialTypeformHelper(
      mycawInitialForm,
    );

    setImpacts({
      physical: initialTypeformHelper.getPhysicalImpacts(),
      psychological: initialTypeformHelper.getPsychologicalImpacts(),
      affectedRelationships: initialTypeformHelper.getAffectedRelationships(),
    });
  }, [mycawInitialForm]);

  return impacts;
};
