import {
  MycawFollowUpTypeformData,
  MycawInitialTypeformData,
} from '@packages/core-shared';

export const getOrderedTypeformFields = (
  form: MycawFollowUpTypeformData | MycawInitialTypeformData,
) => {
  const fields = Object.keys(form)
    .map((key) => form[key])
    .filter((field) => !!field.ref);
  fields.sort((a, b) => a.ref.localeCompare(b.ref));
  return fields;
};
