import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import debounce from 'lodash/debounce';
import styles from './CreateInterimCareSummaryPage.module.scss';
import {
  EbpCase,
  CareDocumentType,
  ReferralLandg,
  displayEbpDocumentType,
  InterimCareSummaryData,
  InterimCareSummaryDataSchema,
  ReferralType,
  Referral,
  getFullname,
  earliestDayTime,
  deepPartialOrNullable,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikDatepicker,
  FormikInputText,
  FormikTextArea,
  Spinner,
} from '@percihealth/react';
import { useParams } from 'react-router-dom';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { CareDocumentsApiRepository } from '@packages/web-shared/api/care-documents';
import { getDownloadURL, ref } from 'firebase/storage';
import ClinicalExperienceAndOutcomes from '../components/ClinicalExperienceAndOutcomes';
import { useGetTypeformChanges } from '../typeforms/useGetTypeformChanges';
import { PdfGeneratedModal } from '../components/PdfGeneratedModal';
import populateClinicalAndExperienceOutcomes from '../populateClinicalAndExperienceOutcomes';
import {
  useLoadPatientAppointmentsByService,
  useLoadServices,
} from '@packages/web-shared/api';
import { useGetFirstAppointmentWithCns } from '../useGetFirstAppointmentWithCns';
import CreateInterimCareSummarySupportProgress, {
  populateSupportProgress,
} from './CreateInterimCareSummarySupportProgress';
import { useFirebase } from '@packages/web-shared';
import { useLoadInitialAssessmentSummary } from '../initialAssessmentSummary/useLoadInitialAssessmentSummary';
import { useLoadWorkFocusedPlan } from '../workFocusedPlan/useLoadWorkFocusedPlan';
import {
  useLoadInterimCareSummary,
  useLoadInterimCareSummarypAutosaved,
} from './useLoadInterimCareSummary';
import { defaultExpertSummary } from './defaultExpertSummary';

export default function CreateInterimCareSummaryPage() {
  const params = useParams();
  const caseId = params?.caseId ?? null;

  if (!caseId) {
    return <div>Case ID (email) should be specified</div>;
  }

  const { storage, auth } = useFirebase();

  const [openPdfModal, setOpenPdfModal] = useState(false);
  useEffect(() => {
    setOpenPdfModal(false);
  }, [caseId]);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useGetFirestoreDocument<EbpCase>('cases', caseId);

  const firstAppointmentWithCns = useGetFirstAppointmentWithCns(
    ebpCase?.patient?.email,
  );

  const docLoader = useLoadInterimCareSummary(ebpCase);
  const docAutosavedLoader = useLoadInterimCareSummarypAutosaved(ebpCase);

  const [autosaveError, setAutosaveError] = useState<string | null>(null);
  useEffect(() => {
    setAutosaveError(null);
  }, [caseId]);

  const autoSave = async (values: any) => {
    console.log('autosave');
    setAutosaveError(null);
    try {
      values.caseId = caseId;
      await docsApiRepository.autosaveInterimCareSummaryForm(values);
    } catch (error: unknown) {
      console.error(error);
      setAutosaveError('Failed to autosave');
    }
  };
  const debouncedAutosave = useRef(debounce(autoSave, 2000));

  const workFocusedDocLoader = useLoadWorkFocusedPlan(
    ebpCase?.documents?.workFocusedPlan?.id,
  );
  const initialAssessmentSummaryLoader =
    useLoadInitialAssessmentSummary(ebpCase);

  const servicesLoader = useLoadServices();

  const initialAssessmentSummaryProfessionalsReferred = useMemo(
    () =>
      initialAssessmentSummaryLoader.record?.professionalsReferred
        ?.filter((ps) => !!ps.service?.id)
        .map((ps) => Number(ps.service.id)) ?? [],
    [initialAssessmentSummaryLoader.record],
  );
  const appointmentsByServiceLoader = useLoadPatientAppointmentsByService({
    patientEmail: ebpCase?.patient.email,
    recommendedServices: initialAssessmentSummaryProfessionalsReferred,
  });

  const [createResponseError, setCreateResponseError] = useState<string | null>(
    null,
  );

  const handleOnSubmit = async (
    values: InterimCareSummaryData,
    actions: FormikHelpers<InterimCareSummaryData>,
  ) => {
    try {
      actions.setSubmitting(true);
      setCreateResponseError(null);
      const { storageRef } =
        await docsApiRepository.createInterimCareSummary(values);

      const previewUrl = await getDownloadURL(ref(storage, storageRef));

      setOpenPdfModal(true);

      // Open the file in a new tab, so it is not automatically downloaded to the local storage
      window.open(previewUrl, '_blank');
    } catch (error: unknown) {
      let errorMsg = '';

      if (error instanceof Error) {
        errorMsg = error.message;
      } else {
        errorMsg = JSON.stringify(error);
      }

      console.error(errorMsg);
      setCreateResponseError(errorMsg);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { record: referral } = useGetFirestoreDocument<Referral>(
    'referrals',
    ebpCase?.referralId,
  );

  const gipCode = useMemo(() => {
    if (referral?.type !== ReferralType.landg) {
      return '';
    }

    return (referral as ReferralLandg)?.gip?.code ?? '';
  }, [referral]);

  const mycawLoader = useGetTypeformChanges({
    ebpCase,
  });

  const formikDependencies = [
    docLoader.record,
    docAutosavedLoader.record,
    workFocusedDocLoader.record,
    initialAssessmentSummaryLoader?.record,
    mycawLoader,
    ebpCase,
    gipCode,
    firstAppointmentWithCns,
    servicesLoader.services,
    appointmentsByServiceLoader.records,
  ];
  const initialValues: InterimCareSummaryData = useMemo(() => {
    let docData = docAutosavedLoader.record ?? docLoader.record;
    if (docData) {
      // Deep clone
      try {
        // schema cast is required to have Date type instead of strings
        docData = deepPartialOrNullable(InterimCareSummaryDataSchema).cast(
          JSON.parse(JSON.stringify(docData)),
        ) as InterimCareSummaryData;
      } catch {
        // intentionally left blank
      }
    }
    const result =
      docData ??
      ({
        caseId,
        type: CareDocumentType.InterimCareSummary,
        patient: {
          fullName: ebpCase?.patient ? getFullname(ebpCase.patient) : '',
          dob:
            ebpCase?.patient?.dateOfBirth ??
            initialAssessmentSummaryLoader.record?.patient?.dob ??
            earliestDayTime(new Date()),
          cancerType:
            initialAssessmentSummaryLoader?.record?.patient?.cancerType ??
            ebpCase?.patient?.medicalHistory?.sentToHealee?.cancerType ??
            '',
          work: {
            dateSignedOff:
              initialAssessmentSummaryLoader.record?.patient?.work
                ?.dateSignedOff ?? new Date(),
            hideHopingToReturnToWorkIn: false,
            hopingToReturnToWorkIn:
              initialAssessmentSummaryLoader.record?.patient?.work
                ?.hopingToReturnToWorkIn ?? '',
            jobTitle:
              initialAssessmentSummaryLoader.record?.patient?.work?.jobTitle ??
              '',
            workPattern:
              initialAssessmentSummaryLoader.record?.patient?.work
                ?.workPattern ?? '',
          },
        },
        gip: {
          code: gipCode,
        },
        perciStartDate:
          initialAssessmentSummaryLoader?.record?.perciStartDate ??
          firstAppointmentWithCns?.date ??
          new Date(new Date().getFullYear(), 1, 1),
        clinicalAndExperienceOutcomes: {
          mycawConcern1: mycawLoader.mycawConcern1 ?? {},
          mycawConcern2: mycawLoader.mycawConcern2 ?? {},
          overallWellbeing: mycawLoader.overallWellbeing ?? {},
          mostImportantAspectOfSupport:
            mycawLoader.mostImportantAspectOfSupport,
          // Other factors are not used in this report
          // otherFactors: undefined,
          returnToWorkSelfEfficacy: mycawLoader.returnToWorkSelfEfficacy ?? {},
          fatigue: mycawLoader.fatigue ?? {},
        },
        expertSummary: defaultExpertSummary,

        dateOfInterimSummary: earliestDayTime(new Date()),
        supportProgress: [],
      } satisfies InterimCareSummaryData);

    // Correct old autosaved data
    populateClinicalAndExperienceOutcomes(
      result.clinicalAndExperienceOutcomes,
      { populateOtherFactors: false },
      mycawLoader,
    );

    if (!result.patient.dob) {
      result.patient.dob =
        ebpCase?.patient?.dateOfBirth ??
        initialAssessmentSummaryLoader.record?.patient?.dob ??
        earliestDayTime(new Date());
    }

    result.supportProgress = populateSupportProgress(
      result.supportProgress,
      servicesLoader.services,
      appointmentsByServiceLoader.records,
    );

    return result;
  }, [...formikDependencies]);

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: InterimCareSummaryDataSchema.isValidSync(initialValues),
    initialValues: initialValues,
    validationSchema: InterimCareSummaryDataSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    formik.resetForm();
  }, [...formikDependencies]);

  useEffect(() => {
    if (formik?.dirty) {
      debouncedAutosave.current(formik.values);
    }
  }, [formik?.values, formik?.dirty]);

  if (caseLoading || docLoader.loading) {
    return <Spinner />;
  }

  if (caseLoadingErrorMessage || !ebpCase) {
    return (
      <AlertError>{caseLoadingErrorMessage ?? 'Not found case'}</AlertError>
    );
  }

  if (docLoader.errorMessage) {
    return <AlertError>{docLoader.errorMessage}</AlertError>;
  }
  if (docAutosavedLoader.errorMessage) {
    return <AlertError>{docAutosavedLoader.errorMessage}</AlertError>;
  }
  if (workFocusedDocLoader.errorMessage) {
    return <AlertError>{workFocusedDocLoader.errorMessage}</AlertError>;
  }
  if (initialAssessmentSummaryLoader.errorMessage) {
    return (
      <AlertError>{initialAssessmentSummaryLoader.errorMessage}</AlertError>
    );
  }

  return (
    <FormikProvider value={formik}>
      <div>
        {autosaveError && <AlertError>{autosaveError}</AlertError>}
        <Form onSubmit={formik.handleSubmit}>
          <FormFieldset title="Patient's details">
            <FormikInputText
              id="patient.fullName"
              name="patient.fullName"
              required
              label="Patient full name"
              value={formik.values.patient.fullName}
            />
            <FormikDatepicker
              id="patient.dob"
              name="patient.dob"
              required
              label="Patient DOB"
              className="w-full"
              value={formik.values.patient.dob}
            />
            <FormikInputText
              id="patient.cancerType"
              name="patient.cancerType"
              required
              label="Cancer type"
              value={formik.values.patient.cancerType}
            />
          </FormFieldset>

          <FormFieldset title="Patient work details">
            <FormikDatepicker
              id="patient.work.dateSignedOff"
              name="patient.work.dateSignedOff"
              required
              label="Date signed-off work"
              value={formik.values.patient.work.dateSignedOff}
            />
            <FormikInputText
              id="patient.work.jobTitle"
              name="patient.work.jobTitle"
              required
              label="Job title"
              value={formik.values.patient.work.jobTitle}
            />
            <FormikInputText
              id="patient.work.workPattern"
              name="patient.work.workPattern"
              required
              label="Work pattern"
              value={formik.values.patient.work.workPattern}
            />
            <FormikInputText
              id="patient.work.hopingToReturnToWorkIn"
              name="patient.work.hopingToReturnToWorkIn"
              required
              label="Hoping to return to work in"
              value={formik.values.patient.work.hopingToReturnToWorkIn}
              checked={!formik.values.patient.work.hideHopingToReturnToWorkIn}
              disabled={formik.values.patient.work.hideHopingToReturnToWorkIn}
              onCheckedChange={() => {
                const newHideHopingToReturnToWorkIn =
                  !formik.values.patient.work.hideHopingToReturnToWorkIn;
                if (newHideHopingToReturnToWorkIn) {
                  formik.setFieldValue(
                    'patient.work.hopingToReturnToWorkIn',
                    '',
                    true,
                  );
                }
                formik.setFieldValue(
                  'patient.work.hideHopingToReturnToWorkIn',
                  newHideHopingToReturnToWorkIn,
                  true,
                );
              }}
            />
          </FormFieldset>

          <FormFieldset title="Care details">
            <FormikDatepicker
              id="perciStartDate"
              name="perciStartDate"
              required
              label="First appointment date with CNS"
              value={formik.values.perciStartDate}
            />
            <FormikInputText
              id="gip.code"
              name="gip.code"
              required
              disabled={!!gipCode}
              label="Group income protection code"
              value={formik.values.gip.code}
            />
            <FormikDatepicker
              id="dateOfInterimSummary"
              name="dateOfInterimSummary"
              required
              label="Date of interim summary"
              value={formik.values.dateOfInterimSummary}
            />
          </FormFieldset>

          <FormFieldset title="Clinical outcomes">
            <ClinicalExperienceAndOutcomes
              clinicalAndExperienceOutcomes={
                formik.values.clinicalAndExperienceOutcomes
              }
            />
          </FormFieldset>

          <FormFieldset title="Perci care team">
            <CreateInterimCareSummarySupportProgress
              onChange={(arr) => formik.setFieldValue('supportProgress', arr)}
              values={formik.values.supportProgress}
            />
          </FormFieldset>

          <FormFieldset title="Cancer nurse specialist summary">
            <FormikTextArea
              className="full-width"
              name="expertSummary.motivation"
              rows={4}
              label="Motivation and programme engagement"
              required
              value={formik.values.expertSummary.motivation}
            />
          </FormFieldset>

          <hr />
          <div className={styles.full}>
            <Button
              type="submit"
              className={styles.submit}
              fullWidth
              disabled={!formik.isValid}
              submitting={formik.isSubmitting}
            >
              Create{' '}
              {displayEbpDocumentType(CareDocumentType.InterimCareSummary)}
            </Button>
          </div>
          {createResponseError && (
            <AlertError>{createResponseError}</AlertError>
          )}
        </Form>
      </div>
      <PdfGeneratedModal
        caseId={caseId}
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
      />
    </FormikProvider>
  );
}
