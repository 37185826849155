import {
  CareDocumentType,
  EbpCase,
  InitialAssessmentSummary,
  InitialAssessmentSummaryData,
  OrganizationId,
  ServiceInfo,
} from '@packages/core-shared';
import { useLoadServices } from '@packages/web-shared/api';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useMemo } from 'react';
import { defaultHna } from './defaultHna';

export const useLoadInitialAssessmentSummary = (ebpCase: EbpCase | null) => {
  const docLoader = useGetFirestoreDocument<InitialAssessmentSummary>(
    'documents',
    ebpCase?.documents?.initialAssessmentSummary?.id ?? null,
  );

  const servicesLoader = useLoadServices();

  const record = useMemo(() => {
    const doc = docLoader.record;
    migrateOldDataStructure(doc, ebpCase, servicesLoader.services);

    return doc;
  }, [docLoader.record, servicesLoader.services]);

  const result = useMemo(
    () => ({
      record,
      loading: docLoader.loading || servicesLoader.servicesLoading,
      errorMessage: docLoader.errorMessage ?? servicesLoader.servicesError,
    }),
    [
      record,
      docLoader.loading,
      servicesLoader.servicesLoading,
      docLoader.errorMessage,
      servicesLoader.servicesError,
    ],
  );

  return result;
};

export const useLoadInitialAssessmentSummaryAutosaved = (
  ebpCase: EbpCase | null,
) => {
  const docAutosaved = useGetFirestoreDocument<InitialAssessmentSummaryData>(
    `cases/${ebpCase?.id ?? 'UNKNOWN_CASE'}/documents-autosaved`,
    CareDocumentType.InitialAssessmentSummary,
  );

  const servicesLoader = useLoadServices();

  const record = useMemo(() => {
    const doc = docAutosaved.record;
    migrateOldDataStructure(doc, ebpCase, servicesLoader.services);

    return doc;
  }, [docAutosaved.record, servicesLoader.services]);

  const result = useMemo(
    () => ({
      record,
      loading: docAutosaved.loading || servicesLoader.servicesLoading,
      errorMessage: docAutosaved.errorMessage ?? servicesLoader.servicesError,
    }),
    [
      record,
      docAutosaved.loading,
      servicesLoader.servicesLoading,
      docAutosaved.errorMessage,
      servicesLoader.servicesError,
    ],
  );

  return result;
};

const migrateOldDataStructure = (
  doc: InitialAssessmentSummaryData | null,
  ebpCase: EbpCase | null,
  services: Record<string, ServiceInfo>,
) => {
  if (!doc) {
    return doc;
  }

  // Migrate old data structure

  if (!doc.hna) {
    doc.hna = defaultHna;
  }

  if (doc.clinicalAndExperienceOutcomes?.otherFactors) {
    delete doc.clinicalAndExperienceOutcomes.otherFactors;
  }

  if (
    doc.patient &&
    !doc.patient.level &&
    ebpCase?.organization.id === OrganizationId.landg
  ) {
    doc.patient.level = ebpCase?.patient.level;
  }

  if (
    doc.patient &&
    doc.patient.work &&
    doc.patient.work.hideHopingToReturnToWorkIn === undefined
  ) {
    doc.patient.work.hideHopingToReturnToWorkIn = false;
  }

  // professionalType is renamed to service: {id, name, professionalType}
  // supportType is renamed to service: {id, name, professionalType}
  if (
    doc.professionalsReferred &&
    doc.professionalsReferred.some(
      (pr: any) => pr.professionalType || pr.supportType,
    )
  ) {
    doc.professionalsReferred = [...doc.professionalsReferred].map(
      (pr: any) => {
        const { professionalType, supportType, ...rest } = pr;

        if (!rest.service?.professionalType) {
          return {
            ...rest,
            // id and name will be corrected below
            service: {
              professionalType: professionalType ?? supportType,
            },
          };
        }
        return pr;
      },
    );
  }

  // Populate service id and name, which were missed in the old data structure
  if (doc.professionalsReferred) {
    for (const profServiceRow of doc.professionalsReferred.filter(
      (r) => !r.service?.id && r.service?.professionalType,
    )) {
      const service = Object.values(services).find(
        (s) =>
          s.professional_type &&
          s.professional_type.toLocaleLowerCase() ===
            profServiceRow.service.professionalType?.toLocaleLowerCase(),
      );

      if (service) {
        profServiceRow.service.id =
          Object.keys(services).find((key) => services[key] === service) ?? '';
        profServiceRow.service.name = service.name;
      }
    }
  }
};
