import {
  CareDocumentType,
  Pcp,
  PcpData,
  RecommendedSupportStatus,
} from '@packages/core-shared';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useMemo } from 'react';

export const useLoadPcp = (pcpId?: string) => {
  const docLoader = useGetFirestoreDocument<Pcp>('documents', pcpId ?? null);

  const record = useMemo(() => {
    const doc = docLoader.record;
    migrateOldDataStructure(doc);

    return doc;
  }, [docLoader.record]);

  const result = useMemo(
    () => ({
      record,
      loading: docLoader.loading,
      errorMessage: docLoader.errorMessage,
    }),
    [record, docLoader.loading, docLoader.errorMessage],
  );

  return result;
};

export const useLoadPcpAutosaved = (caseId: string) => {
  const docAutosaved = useGetFirestoreDocument<PcpData>(
    `cases/${caseId}/documents-autosaved`,
    CareDocumentType.PersonalisedCarePlan,
  );

  const record = useMemo(() => {
    const doc = docAutosaved.record;
    migrateOldDataStructure(doc);

    return doc;
  }, [docAutosaved.record]);

  const result = useMemo(
    () => ({
      record,
      loading: docAutosaved.loading,
      errorMessage: docAutosaved.errorMessage,
    }),
    [record, docAutosaved.loading, docAutosaved.errorMessage],
  );

  return result;
};

const migrateOldDataStructure = (doc: PcpData | null) => {
  // Migration for old data structure
  if (!doc) {
    return;
  }

  if (doc.patientServices) {
    for (const ps of doc.patientServices) {
      const oldPsDoc = ps as any;
      if (oldPsDoc.id && !ps.service) {
        ps.service = {
          id: oldPsDoc.id,
          name: oldPsDoc.name,
          healee_ref: oldPsDoc.healee_ref,
        };

        delete oldPsDoc.id;
        delete oldPsDoc.name;
        delete oldPsDoc.healee_ref;
      }

      if (!ps.status) {
        ps.status = RecommendedSupportStatus.pending;
      }
    }
  }

  return doc;
};
