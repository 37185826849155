import { Link } from 'react-router-dom';
import { DropdownSearch } from '@percihealth/react';
import { useState } from 'react';
import styles from './MycawInitialTypeformLink.module.scss';
import { CancerStatus } from '@packages/core-shared';

enum Options {
  LivingWithOrBeyond = 'Living With / Living Beyond',
  Carer = 'Carer',
}

const options = [Options.LivingWithOrBeyond, Options.Carer].map((o) => ({
  value: o,
  name: o,
}));

interface Props {
  email?: string;
  fullName?: string;
  cancerStatus?: CancerStatus;
}

export const MycawInitialTypeformLink = ({
  email,
  fullName,
  cancerStatus,
}: Props) => {
  const [option, setOption] = useState(
    cancerStatus && [CancerStatus.carer].includes(cancerStatus)
      ? Options.Carer
      : Options.LivingWithOrBeyond,
  );

  if (!email || !fullName) {
    return <></>;
  }

  const formId =
    option === Options.LivingWithOrBeyond
      ? process.env.REACT_APP_MYCAW_INITIAL_FORM_LIVING_WITH_ID
      : process.env.REACT_APP_MYCAW_INITIAL_FORM_CARER_ID;

  const link = `https://percihealth.typeform.com/to/${formId}#email=${encodeURIComponent(
    email,
  )}&name=${encodeURIComponent(fullName)}`;

  return (
    <div className={styles.container}>
      <DropdownSearch
        width="auto"
        multiple={false}
        value={option}
        onChange={(v) => setOption(v as Options)}
        options={options}
      />
      <Link to={link} target="_blank">
        Add initial form
      </Link>
    </div>
  );
};
