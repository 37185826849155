import {
  CareDocumentType,
  earliestDayTime,
  EbpCase,
  InterimCareSummary,
  InterimCareSummaryData,
  OrganizationId,
} from '@packages/core-shared';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useMemo } from 'react';
import { defaultExpertSummary } from './defaultExpertSummary';

export const useLoadInterimCareSummary = (ebpCase: EbpCase | null) => {
  const docLoader = useGetFirestoreDocument<InterimCareSummary>(
    'documents',
    ebpCase?.documents?.interimCareSummary?.id ?? null,
  );

  const record = useMemo(() => {
    const doc = docLoader.record;
    migrateOldDataStructure(doc, ebpCase);

    return doc;
  }, [docLoader.record]);

  const result = useMemo(
    () => ({
      record,
      loading: docLoader.loading,
      errorMessage: docLoader.errorMessage,
    }),
    [record, docLoader.loading, docLoader.errorMessage],
  );

  return result;
};

export const useLoadInterimCareSummarypAutosaved = (
  ebpCase: EbpCase | null,
) => {
  const docAutosaved = useGetFirestoreDocument<InterimCareSummaryData>(
    `cases/${ebpCase?.id ?? 'UNKNOWN_CASE'}/documents-autosaved`,
    CareDocumentType.InterimCareSummary,
  );

  const record = useMemo(() => {
    const doc = docAutosaved.record;
    migrateOldDataStructure(doc, ebpCase);

    return doc;
  }, [docAutosaved.record]);

  const result = useMemo(
    () => ({
      record,
      loading: docAutosaved.loading,
      errorMessage: docAutosaved.errorMessage,
    }),
    [record, docAutosaved.loading, docAutosaved.errorMessage],
  );

  return result;
};
const migrateOldDataStructure = (
  doc: InterimCareSummaryData | null,
  ebpCase: EbpCase | null,
) => {
  // Migration for old data structure
  if (!doc) {
    return doc;
  }
  if (!doc.dateOfInterimSummary) {
    doc.dateOfInterimSummary = earliestDayTime(new Date());
  }

  if (!doc.expertSummary) {
    doc.expertSummary = defaultExpertSummary;
  } else if (typeof doc.expertSummary === 'string') {
    doc.expertSummary = {
      motivation: doc.expertSummary,
    };
  }

  if (doc.clinicalAndExperienceOutcomes?.otherFactors) {
    delete doc.clinicalAndExperienceOutcomes.otherFactors;
  }

  if (
    doc.patient &&
    !doc.patient.level &&
    ebpCase?.organization.id === OrganizationId.landg
  ) {
    doc.patient.level = ebpCase?.patient.level;
  }
  if (
    doc.patient &&
    doc.patient.work &&
    doc.patient.work.hideHopingToReturnToWorkIn === undefined
  ) {
    doc.patient.work.hideHopingToReturnToWorkIn = false;
  }

  if (!doc.supportProgress) {
    doc.supportProgress = [];
  }

  return doc;
};
