import { CareDocumentColumn } from '@packages/core-shared';

export const defaultHna: CareDocumentColumn[] = [
  {
    header: 'Physical impacts',
    values: [],
  },
  {
    header: 'Psychological impacts',
    values: [],
  },
  {
    header: 'Social impacts',
    values: [],
  },
];
